<template>
    <section id="portfolio">
        <AccordionItem 
        id="18"
        sectionId="DesignPortfolio"
        sectionName="Portfolio"
        spanName="Portfolio">
            <PortfolioInfo />
        </AccordionItem>
    </section>
</template>

<script>
import AccordionItem from '@/components/AccordionItem.vue';
import PortfolioInfo from './PortfolioInfo.vue';

export default {
    name: 'DesignPortfolio',
    components: {
        AccordionItem,
        PortfolioInfo,
    },
}
</script>

<style scoped>
section{
    padding: 0 5vw;
}
.container{
    padding-bottom: 2vw;
}
/* RESPONSIVE */
@media (min-width:500px) {}

@media (min-width:768px) {}

@media (min-width:992px) {}

@media (min-width:1200px) {}

@media (min-width:1400px) {}
</style>