<template>
    <a :href=href target="blank_">
        <div class="button">
            <p>
                <i class="ri-arrow-down-line"></i>{{text}}
            </p>
            <div class="decoration"></div>
        </div>
    </a>
</template>

<script>
export default {
    name: 'ButtonDownload',
    props: {
        text: {
            type: String,
        },
        href: {
            type: String,
        },
    },
}
</script>

<style scoped>
a{
    text-decoration: none;
}
.button{
    cursor: pointer;
    width: fit-content;
}
p{ 
    background-color: var(--black);
    border: var(--white) 1px solid;
    font-family: var(--font-monospace);
    color: var(--white);
    text-transform: uppercase;
    margin: 0;
    position: relative;
    z-index: 1;
}
.decoration{
    background-color: var(--white);
    position: relative;    
    z-index: 0;
    transition: all 0.3s ease-in-out;
}
.button:hover .decoration{
    bottom: 20px;
    left: 10px;
}
.ri-arrow-down-line{
    padding-right: 0.5vw;
    margin-left: -0.5vw;
}
@media only screen and (orientation : portrait){
    p{
        font-size: 4.4vw;
        padding: 1.2vw 4vw;
        margin: 0;
    }
    .decoration{
        height: 9.5vw;
        margin-bottom: -9.5vw;
        left: 1.8vw;
        bottom: 7.8vw;
    }
    .button:hover .decoration{
        bottom: 7vw;
        left: 2.8vw;
    }
}
@media only screen and (orientation : landscape){
    p{
        font-size: 1.5vw;
        padding: 0.2vw 1.6vw;
        margin: 0;
    }
    .decoration{
        height: 2.34vw;
        margin-bottom: -1.34vw;
        bottom: 2vw;
        left: 0.5vw;
    }
    .button:hover .decoration{
        bottom: 1.7vw;
        left: 1vw;
    }
}
</style>