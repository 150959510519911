<template>
    <div class="subtitle-container">
        <h3>{{ name }}</h3>
    </div>
</template>

<script>
export default {
    name: 'SubtitleSection',
    components: {
    },
    props:  {
        name: {
            type: String,
        },
    },
}
</script>

<style scoped>
.subtitle-container {
    border-bottom: 2px solid var(--yellow);
    display: inline-block;
    margin-bottom: 4vh;
    height: min-content;
}
h3{
    font-family: var(--font-condensed);
    color: var(--white);
    text-transform: uppercase;
    font-weight: 100;
    font-style: normal;
    margin: 0;
    font-size: 9.43vh;
    opacity: 0.9;
    text-align: end;
    line-height: 8.4vh;
    letter-spacing: 0.5px;
}

@media only screen and (orientation : portrait){
}
@media only screen and (orientation : landscape){
}
</style>
