import { createRouter, createWebHashHistory } from "vue-router";
import DesignPage from '@/views/DesignVersion/DesignPage.vue';
import DeveloperPage from '@/views/DeveloperVersion/DeveloperPage.vue'

const routes = [
    { path: '/', component: DesignPage },
    { path: '/diseño', component: DesignPage },
    { path: '/desarrollo', component: DeveloperPage },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0);
    next();
});

export default router;