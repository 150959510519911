import Kausana_1_320_Jpg from '@/assets/img/frontend/kausana/jpg/kausana-1_320.jpg';
import Kausana_1_480_Jpg from '@/assets/img/frontend/kausana/jpg/kausana-1_480.jpg';
import Kausana_1_768_Jpg from '@/assets/img/frontend/kausana/jpg/kausana-1_768.jpg';
import Kausana_1_320_Png from '@/assets/img/frontend/kausana/png/kausana-1_320.png';
import Kausana_1_480_Png from '@/assets/img/frontend/kausana/png/kausana-1_480.png';
import Kausana_1_768_Png from '@/assets/img/frontend/kausana/png/kausana-1_768.png';
import Kausana_1_320_Avif from '@/assets/img/frontend/kausana/avif/kausana-1_320.avif';
import Kausana_1_480_Avif from '@/assets/img/frontend/kausana/avif/kausana-1_480.avif';
import Kausana_1_768_Avif from '@/assets/img/frontend/kausana/avif/kausana-1_768.avif';
import Kausana_1_320_Webp from '@/assets/img/frontend/kausana/webp/kausana-1_320.webp';
import Kausana_1_480_Webp from '@/assets/img/frontend/kausana/webp/kausana-1_480.webp';
import Kausana_1_768_Webp from '@/assets/img/frontend/kausana/webp/kausana-1_768.webp';

import Hello_1_320_Jpg from '@/assets/img/frontend/helloword/jpg/hello-1_320.jpg';
import Hello_1_480_Jpg from '@/assets/img/frontend/helloword/jpg/hello-1_480.jpg';
import Hello_1_768_Jpg from '@/assets/img/frontend/helloword/jpg/hello-1_768.jpg';
import Hello_1_320_Png from '@/assets/img/frontend/helloword/png/hello-1_320.png';
import Hello_1_480_Png from '@/assets/img/frontend/helloword/png/hello-1_480.png';
import Hello_1_768_Png from '@/assets/img/frontend/helloword/png/hello-1_768.png';
import Hello_1_320_Avif from '@/assets/img/frontend/helloword/avif/hello-1_320.avif';
import Hello_1_480_Avif from '@/assets/img/frontend/helloword/avif/hello-1_480.avif';
import Hello_1_768_Avif from '@/assets/img/frontend/helloword/avif/hello-1_768.avif';
import Hello_1_320_Webp from '@/assets/img/frontend/helloword/webp/hello-1_320.webp';
import Hello_1_480_Webp from '@/assets/img/frontend/helloword/webp/hello-1_480.webp';
import Hello_1_768_Webp from '@/assets/img/frontend/helloword/webp/hello-1_768.webp';

import TecnoNews_1_320_Jpg from '@/assets/img/frontend/tecnonews/jpg/tecnonews-1_320.jpg';
import TecnoNews_1_480_Jpg from '@/assets/img/frontend/tecnonews/jpg/tecnonews-1_480.jpg';
import TecnoNews_1_768_Jpg from '@/assets/img/frontend/tecnonews/jpg/tecnonews-1_768.jpg';
import TecnoNews_1_320_Png from '@/assets/img/frontend/tecnonews/png/tecnonews-1_320.png';
import TecnoNews_1_480_Png from '@/assets/img/frontend/tecnonews/png/tecnonews-1_480.png';
import TecnoNews_1_768_Png from '@/assets/img/frontend/tecnonews/png/tecnonews-1_768.png';
import TecnoNews_1_320_Avif from '@/assets/img/frontend/tecnonews/avif/tecnonews-1_320.avif';
import TecnoNews_1_480_Avif from '@/assets/img/frontend/tecnonews/avif/tecnonews-1_480.avif';
import TecnoNews_1_768_Avif from '@/assets/img/frontend/tecnonews/avif/tecnonews-1_768.avif';
import TecnoNews_1_320_Webp from '@/assets/img/frontend/tecnonews/webp/tecnonews-1_320.webp';
import TecnoNews_1_480_Webp from '@/assets/img/frontend/tecnonews/webp/tecnonews-1_480.webp';
import TecnoNews_1_768_Webp from '@/assets/img/frontend/tecnonews/webp/tecnonews-1_768.webp';

import Alalata_1_320_Jpg from '@/assets/img/frontend/alalata/jpg/alalata-1_320.jpg';
import Alalata_1_480_Jpg from '@/assets/img/frontend/alalata/jpg/alalata-1_480.jpg';
import Alalata_1_768_Jpg from '@/assets/img/frontend/alalata/jpg/alalata-1_768.jpg';
import Alalata_1_320_Png from '@/assets/img/frontend/alalata/png/alalata-1_320.png';
import Alalata_1_480_Png from '@/assets/img/frontend/alalata/png/alalata-1_480.png';
import Alalata_1_768_Png from '@/assets/img/frontend/alalata/png/alalata-1_768.png';
import Alalata_1_320_Avif from '@/assets/img/frontend/alalata/avif/alalata-1_320.avif';
import Alalata_1_480_Avif from '@/assets/img/frontend/alalata/avif/alalata-1_480.avif';
import Alalata_1_768_Avif from '@/assets/img/frontend/alalata/avif/alalata-1_768.avif';
import Alalata_1_320_Webp from '@/assets/img/frontend/alalata/webp/alalata-1_320.webp';
import Alalata_1_480_Webp from '@/assets/img/frontend/alalata/webp/alalata-1_480.webp';
import Alalata_1_768_Webp from '@/assets/img/frontend/alalata/webp/alalata-1_768.webp';

import Wallar_1_320_Jpg from '@/assets/img/web-design/wallar/jpg/wallar-1_320.jpg';
import Wallar_1_480_Jpg from '@/assets/img/web-design/wallar/jpg/wallar-1_480.jpg';
import Wallar_1_768_Jpg from '@/assets/img/web-design/wallar/jpg/wallar-1_768.jpg';
import Wallar_1_320_Png from '@/assets/img/web-design/wallar/png/wallar-1_320.png';
import Wallar_1_480_Png from '@/assets/img/web-design/wallar/png/wallar-1_480.png';
import Wallar_1_768_Png from '@/assets/img/web-design/wallar/png/wallar-1_768.png';
import Wallar_1_320_Avif from '@/assets/img/web-design/wallar/avif/wallar-1_320.avif';
import Wallar_1_480_Avif from '@/assets/img/web-design/wallar/avif/wallar-1_480.avif';
import Wallar_1_768_Avif from '@/assets/img/web-design/wallar/avif/wallar-1_768.avif';
import Wallar_1_320_Webp from '@/assets/img/web-design/wallar/webp/wallar-1_320.webp';
import Wallar_1_480_Webp from '@/assets/img/web-design/wallar/webp/wallar-1_480.webp';
import Wallar_1_768_Webp from '@/assets/img/web-design/wallar/webp/wallar-1_768.webp';

let webDesign = [
    {
      imageId: 'kausana-1',
      altImg: 'Pantalla de la web oficial de «Kausana».',
      description: `Diseño y desarrollo de web oficial de <span class="bold">«Kausana»</span>, empresa de Desarrollo de Software.`,
      srcAvif: Kausana_1_768_Avif,
      srcAvif320: Kausana_1_320_Avif,
      srcAvif480: Kausana_1_480_Avif,
      srcAvif768: Kausana_1_480_Avif ,
      srcAvif1080: Kausana_1_768_Avif,
      srcWebp: Kausana_1_768_Webp,
      srcWebp320: Kausana_1_320_Webp,
      srcWebp480: Kausana_1_480_Webp,
      srcWebp768: Kausana_1_480_Webp ,
      srcWebp1080: Kausana_1_768_Webp,
      srcPng: Kausana_1_768_Png,
      srcPng320: Kausana_1_320_Png,
      srcPng480: Kausana_1_480_Png,
      srcPng768: Kausana_1_480_Png ,
      srcPng1080: Kausana_1_768_Png,
      srcJpg: Kausana_1_768_Jpg,
      srcJpg320: Kausana_1_320_Jpg,
      srcJpg480: Kausana_1_480_Jpg,
      srcJpg768: Kausana_1_480_Jpg ,
      srcJpg1080: Kausana_1_768_Jpg,
      link: 'https://kausana.cl/',
      txtLink: 'Ir a Kausana',
    },
    {
      imageId: 'hello-1',
      altImg: 'Primer diseño de la web personal',
      description: `Diseño y desarrollo de mi anterior página web personal, titulada <span class="bold">«Hello World!»</span>.`,
      srcAvif: Hello_1_768_Avif,
      srcAvif320: Hello_1_320_Avif,
      srcAvif480: Hello_1_480_Avif,
      srcAvif768: Hello_1_480_Avif ,
      srcAvif1080: Hello_1_768_Avif,
      srcWebp: Hello_1_768_Webp,
      srcWebp320: Hello_1_320_Webp,
      srcWebp480: Hello_1_480_Webp,
      srcWebp768: Hello_1_480_Webp ,
      srcWebp1080: Hello_1_768_Webp,
      srcPng: Hello_1_768_Png,
      srcPng320: Hello_1_320_Png,
      srcPng480: Hello_1_480_Png,
      srcPng768: Hello_1_480_Png ,
      srcPng1080: Hello_1_768_Png,
      srcJpg: Hello_1_768_Jpg,
      srcJpg320: Hello_1_320_Jpg,
      srcJpg480: Hello_1_480_Jpg,
      srcJpg768: Hello_1_480_Jpg ,
      srcJpg1080: Hello_1_768_Jpg,
      link: 'https://ceciliadepaula.github.io/helloworld/',
      txtLink: 'Ir a Hello World!',
    },
    {
      imageId: 'wallar-1',
      altImg: 'Desarrollo de «Wallar»',
      description: `Diseño de <span class="bold">«Wallar»</span>, app para conectar a profesionales de la construcción.`,
      srcAvif: Wallar_1_768_Avif,
      srcAvif320: Wallar_1_320_Avif,
      srcAvif480: Wallar_1_480_Avif,
      srcAvif768: Wallar_1_480_Avif ,
      srcAvif1080: Wallar_1_768_Avif,
      srcWebp: Wallar_1_768_Webp,
      srcWebp320: Wallar_1_320_Webp,
      srcWebp480: Wallar_1_480_Webp,
      srcWebp768: Wallar_1_480_Webp ,
      srcWebp1080: Wallar_1_768_Webp,
      srcPng: Wallar_1_768_Png,
      srcPng320: Wallar_1_320_Png,
      srcPng480: Wallar_1_480_Png,
      srcPng768: Wallar_1_480_Png ,
      srcPng1080: Wallar_1_768_Png,
      srcJpg: Wallar_1_768_Jpg,
      srcJpg320: Wallar_1_320_Jpg,
      srcJpg480: Wallar_1_480_Jpg,
      srcJpg768: Wallar_1_480_Jpg ,
      srcJpg1080: Wallar_1_768_Jpg,
      link: 'https://www.behance.net/gallery/96709257/Wallar-UIUX',
      txtLink: 'Ir a Wallar',
    },
    {
      imageId: 'alalata-1',
      altImg: 'Desarrollo de «Alalata»',
      description: `Diseño y desarrollo de <span class="bold">«Alalata»</span>, taller de fotografía estenopeica.`,
      srcAvif: Alalata_1_768_Avif,
      srcAvif320: Alalata_1_320_Avif,
      srcAvif480: Alalata_1_480_Avif,
      srcAvif768: Alalata_1_480_Avif ,
      srcAvif1080: Alalata_1_768_Avif,
      srcWebp: Alalata_1_768_Webp,
      srcWebp320: Alalata_1_320_Webp,
      srcWebp480: Alalata_1_480_Webp,
      srcWebp768: Alalata_1_480_Webp ,
      srcWebp1080: Alalata_1_768_Webp,
      srcPng: Alalata_1_768_Png,
      srcPng320: Alalata_1_320_Png,
      srcPng480: Alalata_1_480_Png,
      srcPng768: Alalata_1_480_Png ,
      srcPng1080: Alalata_1_768_Png,
      srcJpg: Alalata_1_768_Jpg,
      srcJpg320: Alalata_1_320_Jpg,
      srcJpg480: Alalata_1_480_Jpg,
      srcJpg768: Alalata_1_480_Jpg ,
      srcJpg1080: Alalata_1_768_Jpg,
      link: 'https://ceciliadepaula.github.io/alalata/', 
      txtLink: 'Ir a Alalata',
    },
    {
      imageId: 'tecnonews-1',
      altImg: 'Desarrollo de «TecnoNews»',
      description: `Diseño y desarrollo de <span class="bold">«TecnoNews»</span>, portal de noticias tecnológicas.`,
      srcAvif: TecnoNews_1_768_Avif,
      srcAvif320: TecnoNews_1_320_Avif,
      srcAvif480: TecnoNews_1_480_Avif,
      srcAvif768: TecnoNews_1_480_Avif ,
      srcAvif1080: TecnoNews_1_768_Avif,
      srcWebp: TecnoNews_1_768_Webp,
      srcWebp320: TecnoNews_1_320_Webp,
      srcWebp480: TecnoNews_1_480_Webp,
      srcWebp768: TecnoNews_1_480_Webp ,
      srcWebp1080: TecnoNews_1_768_Webp,
      srcPng: TecnoNews_1_768_Png,
      srcPng320: TecnoNews_1_320_Png,
      srcPng480: TecnoNews_1_480_Png,
      srcPng768: TecnoNews_1_480_Png ,
      srcPng1080: TecnoNews_1_768_Png,
      srcJpg: TecnoNews_1_768_Jpg,
      srcJpg320: TecnoNews_1_320_Jpg,
      srcJpg480: TecnoNews_1_480_Jpg,
      srcJpg768: TecnoNews_1_480_Jpg ,
      srcJpg1080: TecnoNews_1_768_Jpg,
      link: 'https://ceciliadepaula.github.io/tecnonews/',
      txtLink: 'Ir a TecnoNews',
    },

    /* 
    novocel pagina vieja
    portal wisecity / novocel / mintral / tempora
    scanwater / scanmineral
    ccu
    tempora web?
    tecnoera
    netexplota diseño web, mailing
    */ 

]

export default webDesign;