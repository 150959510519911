<template>
    <template v-for="(cvSection, index) in Curriculum" :key="index">
        <div class="cv">
            <div class="section">
                <SubtitleSection  :name=cvSection.section />
            </div>        
            <div class="information">
                <template v-for="(logo, index) in cvSection.logos" :key="index">
                    <div :id="logo.logoId" class="logo">
                        <PictureStructure 
                        :srcSvg="logo.srcSvg"
                        :srcAvif320="srcAvif150"
                        :srcWebp320="srcWebp150"
                        :srcPng320="srcPng150"
                        :srcAvif480="srcAvif300"
                        :srcWebp480="srcWebp300"
                        :srcPng480="srcPng300"
                        :altImg="`Logotipo de ${logo.tool}`"
                        classes="soft-and-lang-logo"
                        />
                        <p class="logo-name">{{ logo.tool }}</p>
                    </div>
                </template>
            </div>
        </div>
    </template>
</template>

<script>
import PictureStructure from '@/components/PictureStructure.vue';
import SubtitleSection from '@/components/SubtitleSection.vue';
import Curriculum from './soft_and_lang.js'

export default {
    name: 'CVSoftware',
    components: {
        SubtitleSection, PictureStructure, 
    },
    data() {
        return {
            Curriculum,
        }
    },
}
</script>

<style scoped>
.cv{
    max-width: 80vw;
    margin: auto;
    display: grid;
    grid-template-columns: auto 76%;
    column-gap: 5.5%;
}
.cv .information{
    margin-bottom: 5vh;
    display: grid;
}
.section{
    display: flex;
    flex-direction: row-reverse;
}
.logo{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.logo-name{
    text-align: center;
    padding-top: 2vh;
}

@media only screen and (orientation : portrait){
    .cv .information{
        grid-template-columns: repeat(3, 1fr);
        margin-top: 1vh;
    }
    .cv{
        max-width: 90vw;
        grid-template-columns: 1fr;
    }
    .section{
        flex-direction: row;
    }
    #zeplin{
        display: none
    }
    .logo{
        margin-bottom: 1vh;
    }
}
@media only screen and (orientation : landscape){
    .logo{
        margin-bottom: 5vh;
    }
    .cv .information{
        grid-template-columns: repeat(8, 1fr);
    }
}
@media only screen and (orientation : landscape) and (max-aspect-ratio: 1.8/1) {
    .cv .information{
        margin-top: 3vh;
        grid-template-columns: repeat(8, 1fr);
    }
    .cv{
        max-width: 90vw;
        grid-template-columns: 1fr;
    }
    .section{
        flex-direction: row;
    }
}
@media only screen and (orientation : landscape) and (max-aspect-ratio: 1.5/1) {
    .cv .information{
        margin-top: 3vh;
        grid-template-columns: repeat(6, 1fr);
    }
}
</style>
<style>
.soft-and-lang-logo{
    width: 10vh!important;
    height: 10vh!important;
}
@media only screen and (orientation : portrait){
    .soft-and-lang-logo{
        width: 8vh!important;
        height: 8vh!important;
    }
}
</style>
