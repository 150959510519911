<template>

<!-- hacer cargando con logo
-->

  <DesignNavBar />
  <main>
    <DesignPresentation />
      <div class="accordion-item-container">
        <DesignAboutMe />
        <DesignCV /> 
        <DesignPortfolio />
        <DesignContact />
        <!-- <DesignJustForCurious /> -->
      </div>
  </main>
  <DesignFooter />
</template>

<script>
import DesignNavBar from './DesignGenerals/DesignNavBar.vue';
import DesignPresentation from './DesignPresentation/DesignPresentation.vue';
import DesignAboutMe from './DesignAboutMe/DesignAboutMe.vue';
import DesignCV from './DesignCV/DesignCV.vue'; 
import DesignPortfolio from './DesignPortfolio/DesignPortfolio.vue';
/* import DesignJustForCurious from './DesignJustForCurious/DesignJustForCurious.vue'; */
import DesignContact from './DesignContact/DesignContact.vue';
import DesignFooter from './DesignGenerals/DesignFooter.vue';

export default {
  name: 'DesignPage',
  components: {
    DesignNavBar,
    DesignPresentation,
    DesignAboutMe,
    DesignCV, 
    DesignPortfolio,      
    /* DesignJustForCurious, */
    DesignContact,
    DesignFooter,
  },
}
</script>

<style scoped>
.accordion-item-container{
  background-color: var(--black);
  position: relative;
  z-index: 10;
  padding: 6vh 0;
}
</style>