<template>
    <div class="contact-info">
        <div class="column left">
            <div class="line-up">
                <p class="bolder">Correo electrónico</p>
                <p><a href="mailto:depaulacecilia@gmail.com">depaulacecilia@gmail.com</a></p>
            </div>
            <div class="line-up">
                <p class="bolder">Teléfono</p>
                <p><a href="tel:+56975976975">+56 9 7597 6975</a></p>
            </div>
            <div class="line-up line-down">
                <p class="bolder">Ubicación</p>
                <p>Quillota, Chile</p>
            </div>
        </div>
        <div class="column right">
            <div>
                <p class="bolder">Otros sitios</p>
            </div>
            <div class="line-up">
                <p><a target="_blank" href="https://www.linkedin.com/in/cecilia-de-paula-6b2936190/">LinkedIn</a></p>
                <p><a target="_blank" href="https://www.behance.net/ceciliadepaula">Behance</a></p>
            </div>
            <div class="line-up"> 
                <p><a target="_blank" href="https://gitlab.com/depaulacecilia">GitLab</a></p>
                <p><a target="_blank" href="https://github.com/ceciliadepaula">GitHub</a></p>
            </div>
            <div class="line-up line-down">
                <p><a target="_blank" href="https://www.flickr.com/photos/visionpropia">Flickr</a></p>
                <p><a target="_blank" href="https://www.instagram.com/chechu.mcdp/">Instagram</a></p>
            </div>
        </div>
    </div>
    <div class="statue">
        <PictureStructure 
        :srcAvif="StatueAvif"
        :srcAvif320="StatueAvif320"
        :srcAvif480="StatueAvif480"
        :srcAvif768="StatueAvif768"
        :srcAvif1080="StatueAvif1080"
        :srcWebp="StatueWebp"
        :srcWebp320="StatueWebp320"
        :srcWebp480="StatueWebp480"
        :srcWebp768="StatueWebp768"
        :srcWebp1080="StatueWebp1080"
        :srcPng="StatuePng"
        :srcPng320="StatuePng320"
        :srcPng480="StatuePng480"
        :srcPng768="StatuePng768"
        :srcPng1080="StatuePng1080"
        altImg="Estatua de estilo renacentista de una mujer usando un teclado, con la mirada al frente"
        classes="contact-statue"
        classesAnimation="animate__animated"
        id="contact-statue"
        />
    </div>
</template>

<script>
import PictureStructure from '@/components/PictureStructure.vue';
import StatueAvif from '@/assets/img/statues/avif/contact_statue.avif';
import StatueAvif320 from '@/assets/img/statues/avif/contact_statue_320.avif';
import StatueAvif480 from '@/assets/img/statues/avif/contact_statue_480.avif';
import StatueAvif768 from '@/assets/img/statues/avif/contact_statue_768.avif';
import StatueAvif1080 from '@/assets/img/statues/avif/contact_statue_1080.avif';
import StatuePng from '@/assets/img/statues/png/contact_statue.png';
import StatuePng320 from '@/assets/img/statues/png/contact_statue_320.png';
import StatuePng480 from '@/assets/img/statues/png/contact_statue_480.png';
import StatuePng768 from '@/assets/img/statues/png/contact_statue_768.png';
import StatuePng1080 from '@/assets/img/statues/png/contact_statue_1080.png';
import StatueWebp from '@/assets/img/statues/webp/contact_statue.webp';
import StatueWebp320 from '@/assets/img/statues/webp/contact_statue_320.webp';
import StatueWebp480 from '@/assets/img/statues/webp/contact_statue_480.webp';
import StatueWebp768 from '@/assets/img/statues/webp/contact_statue_768.webp';
import StatueWebp1080 from '@/assets/img/statues/webp/contact_statue_1080.webp';

export default {
    name: 'ContactInfo',
    components: {
        PictureStructure,
    },
    props:  {
    },
    data() {
        return {
            StatueAvif, StatueAvif320, StatueAvif480, StatueAvif768, StatueAvif1080,
            StatuePng, StatuePng320, StatuePng480, StatuePng768, StatuePng1080,
            StatueWebp, StatueWebp320, StatueWebp480, StatueWebp768, StatueWebp1080,
        }
    },
}
</script>

<style scoped>
.contact-info{
    display: flex;
    margin-top: 2vh;
    padding-bottom: 7vh;
}
a {
    font-size: 2.75vh;
    text-decoration: none;
    color: var(--white);
    font-weight: 100;
}
p, a{
    display: flex;
    z-index: 5;
}
p.bolder, a:hover{
    font-weight: 500;
}
p{
    margin: 1.5vh 0;
}
.line-up{
    border-top: var(--white) 2px solid;
}
.line-down{
    border-bottom: var(--white) 2px solid;
}
.column{
    width: 45vw;
}
.column.right p{
    justify-content: flex-end;
}
.statue{
    display: flex;
    justify-content: center;
    position: absolute;
    width: 90vw;
    overflow: hidden;
}

@media only screen and (orientation : landscape) and (min-width: 920px){
    .statue{
        bottom: 4vh;
        right: 1.5vw;
    }
}
@media only screen and (orientation : landscape) and (max-width: 920px){
    .statue{
        bottom: 4vh;
        right: -1vw;
    }
}
@media only screen and (orientation : portrait) {
    a {
        font-size: 2.3vh;
    }
    .contact-info{
        margin-top: 2vh;
    }
    .column.right{
        position: relative;
        top: -29.75vh;
    }
    .column.right .line-up{
        border-top: rgba(0,0,0,0) 1px solid;
    }
    .statue{
        bottom: 4vh;
        overflow: visible;
    }
    .contact-info{
        flex-direction: column;
        margin-top: 5vh;
    }
    .column{
        width: 100%;
    }
}
</style>
<style>
.contact-statue{
    max-height: 80vh;
    transform: scaleX(-1);
}
@media only screen and (orientation : portrait) and (max-width: 900px){
    .statue{
        bottom: 3vh;
        right: 10vw;
    }
    .contact-statue{
        min-height: 54vh;
    }
}
</style>
