let cv = [
    {
        section: 'Educación',
        list: [
            {
                id: 2,
                sectionId: 'graphic-designer',
                title: 'Licenciada en Diseño de la Comunicación Visual',
                year: '2008-2019',
                place: 'Universidad Nacional del Litoral',
                city: 'Santa Fe, Argentina',
                description: `
                    Mención de Cuadro de Honor a mejores promedios de la Provincia de Santa Fe en la promoción 2019. 
                    Organizado por Punto Biz y Randstad, con el auspicio del Gobierno de Santa Fe y Grupo Olio.
                `,
            },
            {
                id: 3,
                sectionId: 'acamica',
                title: 'Desarrollo Web Full Stack',
                year: '2020-2021',
                place: 'Acámica',
                city: 'Buenos Aires, Argentina',
                description: `
                    Cátedra a cargo de Daniel Francesch.
                `,
            },
            {
                id: 4,
                sectionId: 'argentina',
                title: 'Plan Argentina Programa',
                year: '2020',
                place: 'Ministerio de Desarrollo Productivo / Cessi Argentina',
                city: 'Buenos Aires, Argentina',
                description: `
                    Plataforma oficial de Argentina Programa
                `,
            },
            {
                id: 5,
                sectionId: 'ux-ui',
                title: 'Diseño UX/UI',
                year: '2020',
                place: 'Coder House',
                city: 'Buenos Aires, Argentina',
                description: `
                    A cargo de Nicolás Omar Zubia
                `,
            },
            {
                id: 6,
                sectionId: 'tcn',
                title: 'Técnica Constructora Nacional',
                year: '2001-2006',
                place: 'Escuela Industrial Superior',
                city: 'Santa Fe, Argentina',
                description: `
                    Anexa a la Facultad de Ingeniería Química
                    de la Universidad Nacional del Litoral
                `,
            },
        ]
    },
    {
        section: 'Experiencia laboral',
        list: [
            {
                id: 7,
                sectionId: 'kausana',
                title: 'Desarrolladora frontend y diseñadora gráfica',
                year: '2020 - 2024',
                place: 'Kausana',
                city: 'Viña del Mar, Chile',
                description: `
                    Trabajo remoto full time principalmente en área de desarrollo frontend y diseño UX/UI de sitios web,
                    con trabajos secundarios de diseño gráfico general. 
                    Se pueden visualizar algunos de los proyectos en la web oficial de la empresa, web cuyo diseño
                    y desarrollo es de autoría propia: <a class="general-link" href="http://kausana.cl" target="blank_">kausana.cl</a>
                `,
            },
            {
                id: 8,
                sectionId: 'freelance',
                title: 'Diseñadora gráfica y fotógrafa',
                year: '2010 - 2020',
                place: 'Freelance',
                city: 'Santa Fe, Argentina',
                description: `
                    Trabajos varios para pequeñas y medianas empresas. Hincapié en diseño web, diseño de identidad y editorial. 
                    Trabajos de Community Management: creación de piezas gráficas y manejo de redes sociales.
                    Fotografía de eventos especializada en eventos musicales.
                `,
            },
            {
                id: 9,
                sectionId: 'pinhole',
                title: 'Dirección y docencia',
                year: '2011-2020',
                place: 'Alalata: taller de fotografía estenopeica',
                city: 'Santa Fe, Argentina',
                description: `
                    Proyecto personal de enseñanza de fotografía estenopeica experimental con enfoque teórico práctico. 
                    Partiendo de la teoría, se le enseña al alumno a construir su cámara propia, a tomar la fotografía
                    y posteriormente a revelarla en el cuarto oscuro.
                `,
            },
            {
                id: 10,
                sectionId: 'research',
                title: 'Investigación Científica',
                year: '2016 - 2017',
                place: 'Universidad Nacional del Litoral',
                city: 'Santa Fe, Argentina',
                description: `
                    Participación en Beca de Iniciación a la Investigación Científica. 
                    Mención obtenida por el trabajo en el XX Encuentro de Jóvenes Investigadores de la Universidad Nacional del Litoral.
                `,
            },
            {
                id: 11,
                sectionId: 'typography',
                title: 'Pasantía en docencia',
                year: '2012',
                place: 'Universidad Nacional del Litoral',
                city: 'Santa Fe, Argentina',
                description: `
                    Pasantía en las materias Tipografía I y Tipografía II, Cátedra Silvia Gonzalez, de la carrera de 
                    Lic. en Diseño de la comunicación Visual en la Facultad de Arquitectura, Diseño y Urbanismo de la UNL.
                `,
            },
            {
                id: 12,
                sectionId: 'rock',
                title: 'Fotógrafa de eventos musicales',
                year: '2010 - 2012',
                place: 'Producciones del Plaza',
                city: 'Santa Fe, Argentina',
                description: `
                    Cobertura fotográfica de eventos musicales de bandas nacionales e internacionales. 
                `,
            },
        ],
    },
    {
        section: 'Exposiciones',
        list: [
            {
                id: 13,
                sectionId: 'bienal',
                title: 'Bienal de Arte Joven',
                year: '2010, 2012, 2014',
                place: 'Organizada por la Universidad Nacional del Litoral',
                city: 'Santa Fe, Argentina',
                description: `
                    Expositora en la 9na, 10ma y 11va edición con obras tituladas «Los ojos ciegos bien abiertos», 
                    «El encuentro» y «Pop.Corn» respectivamente. 
                    Exposiciones realizadas en el Rectorado de la Universidad Nacional del Litoral.
                `,
            },
            {
                id: 14,
                sectionId: 'encuentro',
                title: 'Muestra fotográfica «El encuentro»',
                year: '2013',
                place: 'Organizada por grupo «Once Mujeres»',
                city: 'Santa Fe, Paraná y La Rioja, Argentina',
                description: `
                    Exposición realizada en el Colegio de Funcionarios Públicos Jerarquizados de la Provincia de Santa Fe,
                    en la Casa de la Ciudad en el marco de «Paraná se muestra» organizado por el Honorable Concejo Deliberante de la Ciudad de Paraná,
                    y en el Museo Folclórico de La Rioja dentro del marco de la II Jornadas de Fotografía La Rioja / Famatina
                    organizada por la Cooperativa Amalaya.
                `,
            },
            {
                id: 15,
                sectionId: 'democracia',
                title: 'Muestra fotográfica «Vivir en democracia»',
                year: '2013',
                place: 'Organizada por grupo «Once Mujeres»',
                city: 'Santa Fe, Argentina',
                description: `
                    Exposición realizada en el Hall de la Legislatura Provincial de la ciudad de Santa Fe, 
                    organizada por el vicegobernador de la provincia de Santa Fe y Presidente de la Cámara de Senadores, Dr. Jorge Henn.
                `,
            },
        ]
    },
    {
        section: 'Premios',
        list: [
            {
                id: 16,
                sectionId: 'popcorn',
                title: 'Ganadora campo de expresión Visuales',
                year: '2012',
                place: '10ma. Bienal de Arte Joven',
                city: 'Santa Fe, Argentina',
                description: `
                    Organizada por la Universidad Nacional del Litoral. Título de la obra: «Pop.Corn».
                `,
            },
        ]
    },
    {
        section: 'Jurado',
        list: [
            {
                id: 17,
                sectionId: 'jury',
                title: 'Concurso de fotografía y afiche publicitario',
                year: '2013',
                place: 'Cámara de diputados de la provincia de Santa Fe',
                city: 'Santa Fe, Argentina',
                description: `
                    Jurado en el Concurso «Día de la Mujer» organizado por la Cámara de Diputados de la Provincia de Santa Fe por la Diputada provincial Dra. Griselda Tessio.
                `,
            },
        ]
    },
]


export default cv;