import LogoAdobeIllustratorSvg from '@/assets/img/logos/svg/adobe_illustrator.svg';
import LogoAdobeIllustratorAvif150 from '@/assets/img/logos/avif/adobe_illustrator_150.avif';
import LogoAdobeIllustratorAvif300 from '@/assets/img/logos/avif/adobe_illustrator_300.avif';
import LogoAdobeIllustratorPng150 from '@/assets/img/logos/png/adobe_illustrator_150.png';
import LogoAdobeIllustratorPng300 from '@/assets/img/logos/png/adobe_illustrator_300.png';
import LogoAdobeIllustratorWebp150 from '@/assets/img/logos/webp/adobe_illustrator_150.webp';
import LogoAdobeIllustratorWebp300 from '@/assets/img/logos/webp/adobe_illustrator_300.webp';

import LogoAdobeXDSvg from '@/assets/img/logos/svg/adobe_xd.svg';
import LogoAdobeXDAvif150 from '@/assets/img/logos/avif/adobe_xd_150.avif';
import LogoAdobeXDAvif300 from '@/assets/img/logos/avif/adobe_xd_300.avif';
import LogoAdobeXDPng150 from '@/assets/img/logos/png/adobe_xd_150.png';
import LogoAdobeXDPng300 from '@/assets/img/logos/png/adobe_xd_300.png';
import LogoAdobeXDWebp150 from '@/assets/img/logos/webp/adobe_xd_150.webp';
import LogoAdobeXDWebp300 from '@/assets/img/logos/webp/adobe_xd_300.webp';

import LogoFigmaSvg from '@/assets/img/logos/svg/figma.svg';
import LogoFigmaAvif150 from '@/assets/img/logos/avif/figma_150.avif';
import LogoFigmaAvif300 from '@/assets/img/logos/avif/figma_300.avif';
import LogoFigmaPng150 from '@/assets/img/logos/png/figma_150.png';
import LogoFigmaPng300 from '@/assets/img/logos/png/figma_300.png';
import LogoFigmaWebp150 from '@/assets/img/logos/webp/figma_150.webp';
import LogoFigmaWebp300 from '@/assets/img/logos/webp/figma_300.webp';

import LogoAdobePhotoshopSvg from '@/assets/img/logos/svg/adobe_photoshop.svg';
import LogoAdobePhotoshopAvif150 from '@/assets/img/logos/avif/adobe_photoshop_150.avif';
import LogoAdobePhotoshopAvif300 from '@/assets/img/logos/avif/adobe_photoshop_300.avif';
import LogoAdobePhotoshopPng150 from '@/assets/img/logos/png/adobe_photoshop_150.png';
import LogoAdobePhotoshopPng300 from '@/assets/img/logos/png/adobe_photoshop_300.png';
import LogoAdobePhotoshopWebp150 from '@/assets/img/logos/webp/adobe_photoshop_150.webp';
import LogoAdobePhotoshopWebp300 from '@/assets/img/logos/webp/adobe_photoshop_300.webp';

import LogoAdobeInDesignSvg from '@/assets/img/logos/svg/adobe_indesign.svg';
import LogoAdobeInDesignAvif150 from '@/assets/img/logos/avif/adobe_indesign_150.avif';
import LogoAdobeInDesignAvif300 from '@/assets/img/logos/avif/adobe_indesign_300.avif';
import LogoAdobeInDesignPng150 from '@/assets/img/logos/png/adobe_indesign_150.png';
import LogoAdobeInDesignPng300 from '@/assets/img/logos/png/adobe_indesign_300.png';
import LogoAdobeInDesignWebp150 from '@/assets/img/logos/webp/adobe_indesign_150.webp';
import LogoAdobeInDesignWebp300 from '@/assets/img/logos/webp/adobe_indesign_300.webp';

import LogoAdobeAfterEffectsSvg from '@/assets/img/logos/svg/adobe_after_effects.svg';
import LogoAdobeAfterEffectsAvif150 from '@/assets/img/logos/avif/adobe_after_effects_150.avif';
import LogoAdobeAfterEffectsAvif300 from '@/assets/img/logos/avif/adobe_after_effects_300.avif';
import LogoAdobeAfterEffectsPng150 from '@/assets/img/logos/png/adobe_after_effects_150.png';
import LogoAdobeAfterEffectsPng300 from '@/assets/img/logos/png/adobe_after_effects_300.png';
import LogoAdobeAfterEffectsWebp150 from '@/assets/img/logos/webp/adobe_after_effects_150.webp';
import LogoAdobeAfterEffectsWebp300 from '@/assets/img/logos/webp/adobe_after_effects_300.webp';

import LogoZeplinSvg from '@/assets/img/logos/svg/zeplin.svg';
import LogoZeplinAvif150 from '@/assets/img/logos/avif/zeplin_150.avif';
import LogoZeplinAvif300 from '@/assets/img/logos/avif/zeplin_300.avif';
import LogoZeplinPng150 from '@/assets/img/logos/png/zeplin_150.png';
import LogoZeplinPng300 from '@/assets/img/logos/png/zeplin_300.png';
import LogoZeplinWebp150 from '@/assets/img/logos/webp/zeplin_150.webp';
import LogoZeplinWebp300 from '@/assets/img/logos/webp/zeplin_300.webp';

import LogoVSCSvg from '@/assets/img/logos/svg/visual_studio_code.svg';
import LogoVSCAvif150 from '@/assets/img/logos/avif/visual_studio_code_150.avif';
import LogoVSCAvif300 from '@/assets/img/logos/avif/visual_studio_code_300.avif';
import LogoVSCPng150 from '@/assets/img/logos/png/visual_studio_code_150.png';
import LogoVSCPng300 from '@/assets/img/logos/png/visual_studio_code_300.png';
import LogoVSCWebp150 from '@/assets/img/logos/webp/visual_studio_code_150.webp';
import LogoVSCWebp300 from '@/assets/img/logos/webp/visual_studio_code_300.webp';

import LogoHTMLSvg from '@/assets/img/logos/svg/html.svg';
import LogoHTMLAvif150 from '@/assets/img/logos/avif/html_150.avif';
import LogoHTMLAvif300 from '@/assets/img/logos/avif/html_300.avif';
import LogoHTMLPng150 from '@/assets/img/logos/png/html_150.png';
import LogoHTMLPng300 from '@/assets/img/logos/png/html_300.png';
import LogoHTMLWebp150 from '@/assets/img/logos/webp/html_150.webp';
import LogoHTMLWebp300 from '@/assets/img/logos/webp/html_300.webp';

import LogoCSSSvg from '@/assets/img/logos/svg/css.svg';
import LogoCSSAvif150 from '@/assets/img/logos/avif/css_150.avif';
import LogoCSSAvif300 from '@/assets/img/logos/avif/css_300.avif';
import LogoCSSPng150 from '@/assets/img/logos/png/css_150.png';
import LogoCSSPng300 from '@/assets/img/logos/png/css_300.png';
import LogoCSSWebp150 from '@/assets/img/logos/webp/css_150.webp';
import LogoCSSWebp300 from '@/assets/img/logos/webp/css_300.webp';

import LogoJavascriptSvg from '@/assets/img/logos/svg/javascript.svg';
import LogoJavascriptAvif150 from '@/assets/img/logos/avif/javascript_150.avif';
import LogoJavascriptAvif300 from '@/assets/img/logos/avif/javascript_300.avif';
import LogoJavascriptPng150 from '@/assets/img/logos/png/javascript_150.png';
import LogoJavascriptPng300 from '@/assets/img/logos/png/javascript_300.png';
import LogoJavascriptWebp150 from '@/assets/img/logos/webp/javascript_150.webp';
import LogoJavascriptWebp300 from '@/assets/img/logos/webp/javascript_300.webp';

import LogoGitSvg from '@/assets/img/logos/svg/git.svg';
import LogoGitAvif150 from '@/assets/img/logos/avif/git_150.avif';
import LogoGitAvif300 from '@/assets/img/logos/avif/git_300.avif';
import LogoGitPng150 from '@/assets/img/logos/png/git_150.png';
import LogoGitPng300 from '@/assets/img/logos/png/git_300.png';
import LogoGitWebp150 from '@/assets/img/logos/webp/git_150.webp';
import LogoGitWebp300 from '@/assets/img/logos/webp/git_300.webp';

import LogoSassSvg from '@/assets/img/logos/svg/sass.svg';
import LogoSassAvif150 from '@/assets/img/logos/avif/sass_150.avif';
import LogoSassAvif300 from '@/assets/img/logos/avif/sass_300.avif';
import LogoSassPng150 from '@/assets/img/logos/png/sass_150.png';
import LogoSassPng300 from '@/assets/img/logos/png/sass_300.png';
import LogoSassWebp150 from '@/assets/img/logos/webp/sass_150.webp';
import LogoSassWebp300 from '@/assets/img/logos/webp/sass_300.webp';

import LogoVueSvg from '@/assets/img/logos/svg/vue.svg';
import LogoVueAvif150 from '@/assets/img/logos/avif/vue_150.avif';
import LogoVueAvif300 from '@/assets/img/logos/avif/vue_300.avif';
import LogoVuePng150 from '@/assets/img/logos/png/vue_150.png';
import LogoVuePng300 from '@/assets/img/logos/png/vue_300.png';
import LogoVueWebp150 from '@/assets/img/logos/webp/vue_150.webp';
import LogoVueWebp300 from '@/assets/img/logos/webp/vue_300.webp';

import LogoNodeSvg from '@/assets/img/logos/svg/nodejs.svg';
import LogoNodeAvif150 from '@/assets/img/logos/avif/nodejs_150.avif';
import LogoNodeAvif300 from '@/assets/img/logos/avif/nodejs_300.avif';
import LogoNodePng150 from '@/assets/img/logos/png/nodejs_150.png';
import LogoNodePng300 from '@/assets/img/logos/png/nodejs_300.png';
import LogoNodeWebp150 from '@/assets/img/logos/webp/nodejs_150.webp';
import LogoNodeWebp300 from '@/assets/img/logos/webp/nodejs_300.webp';

import LogoBootstrapSvg from '@/assets/img/logos/svg/bootstrap.svg';
import LogoBootstrapAvif150 from '@/assets/img/logos/avif/bootstrap_150.avif';
import LogoBootstrapAvif300 from '@/assets/img/logos/avif/bootstrap_300.avif';
import LogoBootstrapPng150 from '@/assets/img/logos/png/bootstrap_150.png';
import LogoBootstrapPng300 from '@/assets/img/logos/png/bootstrap_300.png';
import LogoBootstrapWebp150 from '@/assets/img/logos/webp/bootstrap_150.webp';
import LogoBootstrapWebp300 from '@/assets/img/logos/webp/bootstrap_300.webp';

let soft_and_lang = [
    {
        section: 'Herramientas',
        logos: [
            {
                logoId: 'html',
                tool: 'HTML',
                srcSvg: LogoHTMLSvg,
                srcAvif150: LogoHTMLAvif150,
                srcWebp150: LogoHTMLWebp150,
                srcPng150: LogoHTMLPng150,
                srcAvif300: LogoHTMLAvif300,
                srcWebp300: LogoHTMLWebp300,
                srcPng300: LogoHTMLPng300,
            },
            {
                logoId: 'css',
                tool: 'CSS',
                srcSvg: LogoCSSSvg,
                srcAvif150: LogoCSSAvif150,
                srcWebp150: LogoCSSWebp150,
                srcPng150: LogoCSSPng150,
                srcAvif300: LogoCSSAvif300,
                srcWebp300: LogoCSSWebp300,
                srcPng300: LogoCSSPng300,
            },
            {
                logoId: 'js',
                tool: 'Javascript',
                srcSvg: LogoJavascriptSvg,
                srcAvif150: LogoJavascriptAvif150,
                srcWebp150: LogoJavascriptWebp150,
                srcPng150: LogoJavascriptPng150,
                srcAvif300: LogoJavascriptAvif300,
                srcWebp300: LogoJavascriptWebp300,
                srcPng300: LogoJavascriptPng300,
            },
            {
                logoId: 'vue',
                tool: 'Vue',
                srcSvg: LogoVueSvg,
                srcAvif150: LogoVueAvif150,
                srcWebp150: LogoVueWebp150,
                srcPng150: LogoVuePng150,
                srcAvif300: LogoVueAvif300,
                srcWebp300: LogoVueWebp300,
                srcPng300: LogoVuePng300,
            },
            {
                logoId: 'node-js',
                tool: 'NodeJs',
                srcSvg: LogoNodeSvg,
                srcAvif150: LogoNodeAvif150,
                srcWebp150: LogoNodeWebp150,
                srcPng150: LogoNodePng150,
                srcAvif300: LogoNodeAvif300,
                srcWebp300: LogoNodeWebp300,
                srcPng300: LogoNodePng300,
            },
            {
                logoId: 'bootstrap',
                tool: 'Bootstrap',
                srcSvg: LogoBootstrapSvg,
                srcAvif150: LogoBootstrapAvif150,
                srcWebp150: LogoBootstrapWebp150,
                srcPng150: LogoBootstrapPng150,
                srcAvif300: LogoBootstrapAvif300,
                srcWebp300: LogoBootstrapWebp300,
                srcPng300: LogoBootstrapPng300,
            },
            {
                logoId: 'git',
                tool: 'Git',
                srcSvg: LogoGitSvg,
                srcAvif150: LogoGitAvif150,
                srcWebp150: LogoGitWebp150,
                srcPng150: LogoGitPng150,
                srcAvif300: LogoGitAvif300,
                srcWebp300: LogoGitWebp300,
                srcPng300: LogoGitPng300,
            },
            {
                logoId: 'sass',
                tool: 'Sass',
                srcSvg: LogoSassSvg,
                srcAvif150: LogoSassAvif150,
                srcWebp150: LogoSassWebp150,
                srcPng150: LogoSassPng150,
                srcAvif300: LogoSassAvif300,
                srcWebp300: LogoSassWebp300,
                srcPng300: LogoSassPng300,
            },
            {
                logoId: 'vsc',
                tool: 'VSC',
                srcSvg: LogoVSCSvg,
                srcAvif150: LogoVSCAvif150,
                srcWebp150: LogoVSCWebp150,
                srcPng150: LogoVSCPng150,
                srcAvif300: LogoVSCAvif300,
                srcWebp300: LogoVSCWebp300,
                srcPng300: LogoVSCPng300,
            },
            {
                logoId: 'figma',
                tool: 'Figma',
                srcSvg: LogoFigmaSvg,
                srcAvif150: LogoFigmaAvif150,
                srcWebp150: LogoFigmaWebp150,
                srcPng150: LogoFigmaPng150,
                srcAvif300: LogoFigmaAvif300,
                srcWebp300: LogoFigmaWebp300,
                srcPng300: LogoFigmaPng300,
            },
            {
                logoId: 'xd',
                tool: 'XD',
                srcSvg: LogoAdobeXDSvg,
                srcAvif150: LogoAdobeXDAvif150,
                srcWebp150: LogoAdobeXDWebp150,
                srcPng150: LogoAdobeXDPng150,
                srcAvif300: LogoAdobeXDAvif300,
                srcWebp300: LogoAdobeXDWebp300,
                srcPng300: LogoAdobeXDPng300,
            },
            {
                logoId: 'illustrator',
                tool: 'Illustrator',
                srcSvg: LogoAdobeIllustratorSvg,
                srcAvif150: LogoAdobeIllustratorAvif150,
                srcWebp150: LogoAdobeIllustratorWebp150,
                srcPng150: LogoAdobeIllustratorPng150,
                srcAvif300: LogoAdobeIllustratorAvif300,
                srcWebp300: LogoAdobeIllustratorWebp300,
                srcPng300: LogoAdobeIllustratorPng300,
            },
            {
                logoId: 'photoshop',
                tool: 'Photoshop',
                srcSvg: LogoAdobePhotoshopSvg,
                srcAvif150: LogoAdobePhotoshopAvif150,
                srcWebp150: LogoAdobePhotoshopWebp150,
                srcPng150: LogoAdobePhotoshopPng150,
                srcAvif300: LogoAdobePhotoshopAvif300,
                srcWebp300: LogoAdobePhotoshopWebp300,
                srcPng300: LogoAdobePhotoshopPng300,
            },
            {
                logoId: 'after-effects',
                tool: 'After Effects',
                srcSvg: LogoAdobeAfterEffectsSvg,
                srcAvif150: LogoAdobeAfterEffectsAvif150,
                srcWebp150: LogoAdobeAfterEffectsWebp150,
                srcPng150: LogoAdobeAfterEffectsPng150,
                srcAvif300: LogoAdobeAfterEffectsAvif300,
                srcWebp300: LogoAdobeAfterEffectsWebp300,
                srcPng300: LogoAdobeAfterEffectsPng300,
            },
            {
                logoId: 'indesign',
                tool: 'InDesign',
                srcSvg: LogoAdobeInDesignSvg,
                srcAvif150: LogoAdobeInDesignAvif150,
                srcWebp150: LogoAdobeInDesignWebp150,
                srcPng150: LogoAdobeInDesignPng150,
                srcAvif300: LogoAdobeInDesignAvif300,
                srcWebp300: LogoAdobeInDesignWebp300,
                srcPng300: LogoAdobeInDesignPng300,
            },
            {
                logoId: 'zeplin',
                tool: 'Zeplin',
                srcSvg: LogoZeplinSvg,
                srcAvif150: LogoZeplinAvif150,
                srcWebp150: LogoZeplinWebp150,
                srcPng150: LogoZeplinPng150,
                srcAvif300: LogoZeplinAvif300,
                srcWebp300: LogoZeplinWebp300,
                srcPng300: LogoZeplinPng300,
            },
        ],
    }
]


export default soft_and_lang;