<template>
    <template v-for="(cvSection, index) in Curriculum" :key="index">
        <div class="cv">
            <div class="section">
                <SubtitleSection :name=cvSection.section />
            </div>        
            <div class="information">
                <template v-for="(list, index) in cvSection.list" :key="index">
                    <AccordionItem 
                    secondatyAcordion="true" 
                    :id=list.id
                    :sectionId=list.sectionId
                    :sectionName=list.title
                    :spanName=list.year
                    >
                        <p class="place">{{ list.place }} <span v-if="list.city" class="city">/ {{ list.city }}</span></p>
                        <p v-html="formatDescription(list.description)"></p>
                    </AccordionItem>
                </template>
            </div>
        </div>
    </template>
</template>

<script>
import SubtitleSection from '@/components/SubtitleSection.vue';
import AccordionItem from '@/components/AccordionItem.vue';
import DOMPurify from 'dompurify';
import Curriculum from './cv.js'

export default {
    name: 'CVExperience',
    components: {
        SubtitleSection, AccordionItem,
    },
    data() {
        return {
            Curriculum,
        }
    },
    methods: {
        formatDescription(description) {
            const cleanHTML = DOMPurify.sanitize(description);
            return cleanHTML;
        }
    }
}
</script>

<style scoped>
.cv{
    max-width: 80vw;
    margin: auto;
    display: grid;
    grid-template-columns: auto 76%;
    column-gap: 5.5%;
}
.cv .information{
    border-bottom: var(--white) 2px solid;
    margin-bottom: 8vh;
}
.section{
    display: flex;
    flex-direction: row-reverse;
}
.place{
    font-weight: 600;
    margin-bottom: 1vh;
}
.city{
    font-weight: 100;
}
@media only screen and (orientation : portrait) {
    .cv{
        max-width: 90vw;
        grid-template-columns: 1fr;
    }
    .section{
        flex-direction: row;
    }
    .place{
        font-size: 2.6vh;
    }
    .city{
        font-size: 2.5vh;
    }
}
@media only screen and (orientation : landscape) and (max-aspect-ratio: 1.8/1) {
    .cv{
        max-width: 90vw;
        grid-template-columns: 1fr;
    }
    .section{
        flex-direction: row;
    }
    .place{
        font-size: 3vh;
    }
    .city{
        font-size: 2.75vh;
    }
}
</style>
