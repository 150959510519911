import Danilo_1_320_Jpg from '@/assets/img/graphic-design/danilo/jpg/danilo-1_320.jpg';
import Danilo_1_480_Jpg from '@/assets/img/graphic-design/danilo/jpg/danilo-1_480.jpg';
import Danilo_1_768_Jpg from '@/assets/img/graphic-design/danilo/jpg/danilo-1_768.jpg';
import Danilo_1_320_Png from '@/assets/img/graphic-design/danilo/png/danilo-1_320.png';
import Danilo_1_480_Png from '@/assets/img/graphic-design/danilo/png/danilo-1_480.png';
import Danilo_1_768_Png from '@/assets/img/graphic-design/danilo/png/danilo-1_768.png';
import Danilo_1_320_Avif from '@/assets/img/graphic-design/danilo/avif/danilo-1_320.avif';
import Danilo_1_480_Avif from '@/assets/img/graphic-design/danilo/avif/danilo-1_480.avif';
import Danilo_1_768_Avif from '@/assets/img/graphic-design/danilo/avif/danilo-1_768.avif';
import Danilo_1_320_Webp from '@/assets/img/graphic-design/danilo/webp/danilo-1_320.webp';
import Danilo_1_480_Webp from '@/assets/img/graphic-design/danilo/webp/danilo-1_480.webp';
import Danilo_1_768_Webp from '@/assets/img/graphic-design/danilo/webp/danilo-1_768.webp';

import Juan_1_320_Jpg from '@/assets/img/graphic-design/juan/jpg/juan-1_320.jpg';
import Juan_1_480_Jpg from '@/assets/img/graphic-design/juan/jpg/juan-1_480.jpg';
import Juan_1_768_Jpg from '@/assets/img/graphic-design/juan/jpg/juan-1_768.jpg';
import Juan_1_320_Png from '@/assets/img/graphic-design/juan/png/juan-1_320.png';
import Juan_1_480_Png from '@/assets/img/graphic-design/juan/png/juan-1_480.png';
import Juan_1_768_Png from '@/assets/img/graphic-design/juan/png/juan-1_768.png';
import Juan_1_320_Avif from '@/assets/img/graphic-design/juan/avif/juan-1_320.avif';
import Juan_1_480_Avif from '@/assets/img/graphic-design/juan/avif/juan-1_480.avif';
import Juan_1_768_Avif from '@/assets/img/graphic-design/juan/avif/juan-1_768.avif';
import Juan_1_320_Webp from '@/assets/img/graphic-design/juan/webp/juan-1_320.webp';
import Juan_1_480_Webp from '@/assets/img/graphic-design/juan/webp/juan-1_480.webp';
import Juan_1_768_Webp from '@/assets/img/graphic-design/juan/webp/juan-1_768.webp';

import Once_1_320_Jpg from '@/assets/img/graphic-design/once/jpg/once-0_320.jpg';
import Once_1_480_Jpg from '@/assets/img/graphic-design/once/jpg/once-0_480.jpg';
import Once_1_768_Jpg from '@/assets/img/graphic-design/once/jpg/once-0_768.jpg';
import Once_1_320_Png from '@/assets/img/graphic-design/once/png/once-0_320.png';
import Once_1_480_Png from '@/assets/img/graphic-design/once/png/once-0_480.png';
import Once_1_768_Png from '@/assets/img/graphic-design/once/png/once-0_768.png';
import Once_1_320_Avif from '@/assets/img/graphic-design/once/avif/once-0_320.avif';
import Once_1_480_Avif from '@/assets/img/graphic-design/once/avif/once-0_480.avif';
import Once_1_768_Avif from '@/assets/img/graphic-design/once/avif/once-0_768.avif';
import Once_1_320_Webp from '@/assets/img/graphic-design/once/webp/once-0_320.webp';
import Once_1_480_Webp from '@/assets/img/graphic-design/once/webp/once-0_480.webp';
import Once_1_768_Webp from '@/assets/img/graphic-design/once/webp/once-0_768.webp';

import Alalata_1_320_Jpg from '@/assets/img/graphic-design/alalata/jpg/alalata-1_320.jpg';
import Alalata_1_480_Jpg from '@/assets/img/graphic-design/alalata/jpg/alalata-1_480.jpg';
import Alalata_1_768_Jpg from '@/assets/img/graphic-design/alalata/jpg/alalata-1_768.jpg';
import Alalata_1_320_Png from '@/assets/img/graphic-design/alalata/png/alalata-1_320.png';
import Alalata_1_480_Png from '@/assets/img/graphic-design/alalata/png/alalata-1_480.png';
import Alalata_1_768_Png from '@/assets/img/graphic-design/alalata/png/alalata-1_768.png';
import Alalata_1_320_Avif from '@/assets/img/graphic-design/alalata/avif/alalata-1_320.avif';
import Alalata_1_480_Avif from '@/assets/img/graphic-design/alalata/avif/alalata-1_480.avif';
import Alalata_1_768_Avif from '@/assets/img/graphic-design/alalata/avif/alalata-1_768.avif';
import Alalata_1_320_Webp from '@/assets/img/graphic-design/alalata/webp/alalata-1_320.webp';
import Alalata_1_480_Webp from '@/assets/img/graphic-design/alalata/webp/alalata-1_480.webp';
import Alalata_1_768_Webp from '@/assets/img/graphic-design/alalata/webp/alalata-1_768.webp';

import Vientos_1_320_Jpg from '@/assets/img/graphic-design/vientos/jpg/vientos-1_320.jpg';
import Vientos_1_480_Jpg from '@/assets/img/graphic-design/vientos/jpg/vientos-1_480.jpg';
import Vientos_1_768_Jpg from '@/assets/img/graphic-design/vientos/jpg/vientos-1_768.jpg';
import Vientos_1_320_Png from '@/assets/img/graphic-design/vientos/png/vientos-1_320.png';
import Vientos_1_480_Png from '@/assets/img/graphic-design/vientos/png/vientos-1_480.png';
import Vientos_1_768_Png from '@/assets/img/graphic-design/vientos/png/vientos-1_768.png';
import Vientos_1_320_Avif from '@/assets/img/graphic-design/vientos/avif/vientos-1_320.avif';
import Vientos_1_480_Avif from '@/assets/img/graphic-design/vientos/avif/vientos-1_480.avif';
import Vientos_1_768_Avif from '@/assets/img/graphic-design/vientos/avif/vientos-1_768.avif';
import Vientos_1_320_Webp from '@/assets/img/graphic-design/vientos/webp/vientos-1_320.webp';
import Vientos_1_480_Webp from '@/assets/img/graphic-design/vientos/webp/vientos-1_480.webp';
import Vientos_1_768_Webp from '@/assets/img/graphic-design/vientos/webp/vientos-1_768.webp';

import Tipos_1_320_Jpg from '@/assets/img/graphic-design/tipos/jpg/tipos_320.jpg';
import Tipos_1_480_Jpg from '@/assets/img/graphic-design/tipos/jpg/tipos_480.jpg';
import Tipos_1_768_Jpg from '@/assets/img/graphic-design/tipos/jpg/tipos_768.jpg';
import Tipos_1_320_Png from '@/assets/img/graphic-design/tipos/png/tipos_320.png';
import Tipos_1_480_Png from '@/assets/img/graphic-design/tipos/png/tipos_480.png';
import Tipos_1_768_Png from '@/assets/img/graphic-design/tipos/png/tipos_768.png';
import Tipos_1_320_Avif from '@/assets/img/graphic-design/tipos/avif/tipos_320.avif';
import Tipos_1_480_Avif from '@/assets/img/graphic-design/tipos/avif/tipos_480.avif';
import Tipos_1_768_Avif from '@/assets/img/graphic-design/tipos/avif/tipos_768.avif';
import Tipos_1_320_Webp from '@/assets/img/graphic-design/tipos/webp/tipos_320.webp';
import Tipos_1_480_Webp from '@/assets/img/graphic-design/tipos/webp/tipos_480.webp';
import Tipos_1_768_Webp from '@/assets/img/graphic-design/tipos/webp/tipos_768.webp';

import Wallar_1_320_Jpg from '@/assets/img/graphic-design/wallar/jpg/wallar-1_320.jpg';
import Wallar_1_480_Jpg from '@/assets/img/graphic-design/wallar/jpg/wallar-1_480.jpg';
import Wallar_1_768_Jpg from '@/assets/img/graphic-design/wallar/jpg/wallar-1_768.jpg';
import Wallar_1_320_Png from '@/assets/img/graphic-design/wallar/png/wallar-1_320.png';
import Wallar_1_480_Png from '@/assets/img/graphic-design/wallar/png/wallar-1_480.png';
import Wallar_1_768_Png from '@/assets/img/graphic-design/wallar/png/wallar-1_768.png';
import Wallar_1_320_Avif from '@/assets/img/graphic-design/wallar/avif/wallar-1_320.avif';
import Wallar_1_480_Avif from '@/assets/img/graphic-design/wallar/avif/wallar-1_480.avif';
import Wallar_1_768_Avif from '@/assets/img/graphic-design/wallar/avif/wallar-1_768.avif';
import Wallar_1_320_Webp from '@/assets/img/graphic-design/wallar/webp/wallar-1_320.webp';
import Wallar_1_480_Webp from '@/assets/img/graphic-design/wallar/webp/wallar-1_480.webp';
import Wallar_1_768_Webp from '@/assets/img/graphic-design/wallar/webp/wallar-1_768.webp';


let graphicDesign = [
    {
        imageId: 'danilo-1',
        altImg: 'Pieza para redes sociales de «Danilo Gaetano Personal Trainer»',
        description: `Diseño de identidad y contenido para redes sociales de <span class="bold">«Danilo Gaetano Personal Trainer»</span>`,
        srcAvif: Danilo_1_768_Avif,
        srcAvif320: Danilo_1_320_Avif,
        srcAvif480: Danilo_1_480_Avif,
        srcAvif768: Danilo_1_480_Avif ,
        srcAvif1080: Danilo_1_768_Avif,
        srcWebp: Danilo_1_768_Webp,
        srcWebp320: Danilo_1_320_Webp,
        srcWebp480: Danilo_1_480_Webp,
        srcWebp768: Danilo_1_480_Webp ,
        srcWebp1080: Danilo_1_768_Webp,
        srcPng: Danilo_1_768_Png,
        srcPng320: Danilo_1_320_Png,
        srcPng480: Danilo_1_480_Png,
        srcPng768: Danilo_1_480_Png ,
        srcPng1080: Danilo_1_768_Png,
        srcJpg: Danilo_1_768_Jpg,
        srcJpg320: Danilo_1_320_Jpg,
        srcJpg480: Danilo_1_480_Jpg,
        srcJpg768: Danilo_1_480_Jpg ,
        srcJpg1080: Danilo_1_768_Jpg,
        link: 'https://www.behance.net/gallery/82821565/Danilo-Gaetano-Personal-Trainer',
    },
    {
        imageId: 'juan-1',
        altImg: 'Pieza para redes sociales de «Juan Cruz Benitez Profesor de Guitarra & Ukelele»',
        description: `Diseño de contenido para redes sociales de <span class="bold">«Juan Cruz Benitez Profesor de Guitarra & Ukelele»</span>`,
        srcAvif: Juan_1_768_Avif,
        srcAvif320: Juan_1_320_Avif,
        srcAvif480: Juan_1_480_Avif,
        srcAvif768: Juan_1_480_Avif ,
        srcAvif1080: Juan_1_768_Avif,
        srcWebp: Juan_1_768_Webp,
        srcWebp320: Juan_1_320_Webp,
        srcWebp480: Juan_1_480_Webp,
        srcWebp768: Juan_1_480_Webp ,
        srcWebp1080: Juan_1_768_Webp,
        srcPng: Juan_1_768_Png,
        srcPng320: Juan_1_320_Png,
        srcPng480: Juan_1_480_Png,
        srcPng768: Juan_1_480_Png ,
        srcPng1080: Juan_1_768_Png,
        srcJpg: Juan_1_768_Jpg,
        srcJpg320: Juan_1_320_Jpg,
        srcJpg480: Juan_1_480_Jpg,
        srcJpg768: Juan_1_480_Jpg ,
        srcJpg1080: Juan_1_768_Jpg,
        link: 'https://www.behance.net/gallery/100081491/Social-Media',
    },
    {
        imageId: 'once-1',
        altImg: 'Pieza de folletería para el Colectivo de Fotógrafas Independientes «Once Mujeres»',
        description: `Diseño de identidad y material de promoción para el Colectivo de Fotógrafas Independientes <span class="bold">«Once Mujeres»</span>`,
        srcAvif: Once_1_768_Avif,
        srcAvif320: Once_1_320_Avif,
        srcAvif480: Once_1_480_Avif,
        srcAvif768: Once_1_480_Avif ,
        srcAvif1080: Once_1_768_Avif,
        srcWebp: Once_1_768_Webp,
        srcWebp320: Once_1_320_Webp,
        srcWebp480: Once_1_480_Webp,
        srcWebp768: Once_1_480_Webp ,
        srcWebp1080: Once_1_768_Webp,
        srcPng: Once_1_768_Png,
        srcPng320: Once_1_320_Png,
        srcPng480: Once_1_480_Png,
        srcPng768: Once_1_480_Png ,
        srcPng1080: Once_1_768_Png,
        srcJpg: Once_1_768_Jpg,
        srcJpg320: Once_1_320_Jpg,
        srcJpg480: Once_1_480_Jpg,
        srcJpg768: Once_1_480_Jpg ,
        srcJpg1080: Once_1_768_Jpg,
        link: 'https://www.behance.net/gallery/62196545/Once-Mujeres',
    },
    {
        imageId: 'tipos-1',
        altImg: 'Folletería de la Sexta Bienal de Tipografía Latinoamericana «Tipos Latinos 2014»',
        description: `Diseño folletería de la Sexta Bienal de Tipografía Latinoamericana <span class="bold">«Tipos Latinos 2014»</span>, Sede Santa Fe`,
        srcAvif: Tipos_1_768_Avif,
        srcAvif320: Tipos_1_320_Avif,
        srcAvif480: Tipos_1_480_Avif,
        srcAvif768: Tipos_1_480_Avif ,
        srcAvif1080: Tipos_1_768_Avif,
        srcWebp: Tipos_1_768_Webp,
        srcWebp320: Tipos_1_320_Webp,
        srcWebp480: Tipos_1_480_Webp,
        srcWebp768: Tipos_1_480_Webp ,
        srcWebp1080: Tipos_1_768_Webp,
        srcPng: Tipos_1_768_Png,
        srcPng320: Tipos_1_320_Png,
        srcPng480: Tipos_1_480_Png,
        srcPng768: Tipos_1_480_Png ,
        srcPng1080: Tipos_1_768_Png,
        srcJpg: Tipos_1_768_Jpg,
        srcJpg320: Tipos_1_320_Jpg,
        srcJpg480: Tipos_1_480_Jpg,
        srcJpg768: Tipos_1_480_Jpg ,
        srcJpg1080: Tipos_1_768_Jpg,
        link: 'https://www.behance.net/gallery/61391629/Folleteria-Tipos-Latinos-2014',
    },
    {
        imageId: 'wallar-1',
        altImg: 'Desarrollo de «Wallar»',
        description: `Diseño de identidad de <span class="bold">«Wallar»</span>, app para conectar a profesionales de la construcción.`,
        srcAvif: Wallar_1_768_Avif,
        srcAvif320: Wallar_1_320_Avif,
        srcAvif480: Wallar_1_480_Avif,
        srcAvif768: Wallar_1_480_Avif ,
        srcAvif1080: Wallar_1_768_Avif,
        srcWebp: Wallar_1_768_Webp,
        srcWebp320: Wallar_1_320_Webp,
        srcWebp480: Wallar_1_480_Webp,
        srcWebp768: Wallar_1_480_Webp ,
        srcWebp1080: Wallar_1_768_Webp,
        srcPng: Wallar_1_768_Png,
        srcPng320: Wallar_1_320_Png,
        srcPng480: Wallar_1_480_Png,
        srcPng768: Wallar_1_480_Png ,
        srcPng1080: Wallar_1_768_Png,
        srcJpg: Wallar_1_768_Jpg,
        srcJpg320: Wallar_1_320_Jpg,
        srcJpg480: Wallar_1_480_Jpg,
        srcJpg768: Wallar_1_480_Jpg ,
        srcJpg1080: Wallar_1_768_Jpg,
        link: 'https://www.behance.net/gallery/96709257/Wallar-UIUX',
        txtLink: 'Ir a Wallar',
    },
    {
        imageId: 'alalata-1',
        altImg: 'Logotipo de «Alalata Fotografía Estenopeica»',
        description: `Diseño de logotipo y material publicitario para <span class="bold">«Alalata Fotografía Estenopeica»</span>`,
        srcAvif: Alalata_1_768_Avif,
        srcAvif320: Alalata_1_320_Avif,
        srcAvif480: Alalata_1_480_Avif,
        srcAvif768: Alalata_1_480_Avif ,
        srcAvif1080: Alalata_1_768_Avif,
        srcWebp: Alalata_1_768_Webp,
        srcWebp320: Alalata_1_320_Webp,
        srcWebp480: Alalata_1_480_Webp,
        srcWebp768: Alalata_1_480_Webp ,
        srcWebp1080: Alalata_1_768_Webp,
        srcPng: Alalata_1_768_Png,
        srcPng320: Alalata_1_320_Png,
        srcPng480: Alalata_1_480_Png,
        srcPng768: Alalata_1_480_Png ,
        srcPng1080: Alalata_1_768_Png,
        srcJpg: Alalata_1_768_Jpg,
        srcJpg320: Alalata_1_320_Jpg,
        srcJpg480: Alalata_1_480_Jpg,
        srcJpg768: Alalata_1_480_Jpg ,
        srcJpg1080: Alalata_1_768_Jpg,
        link: 'https://www.behance.net/gallery/96872605/Alalata-Fotografia-Estenopeica',
    },
    {
        imageId: 'vientos-1',
        altImg: 'Logotipo de «Vientos Verdes»',
        description: `Diseño de identidad y folletería de la dietética <span class="bold">«Vientos Verdes»<span>`,
        srcAvif: Vientos_1_768_Avif,
        srcAvif320: Vientos_1_320_Avif,
        srcAvif480: Vientos_1_480_Avif,
        srcAvif768: Vientos_1_480_Avif ,
        srcAvif1080: Vientos_1_768_Avif,
        srcWebp: Vientos_1_768_Webp,
        srcWebp320: Vientos_1_320_Webp,
        srcWebp480: Vientos_1_480_Webp,
        srcWebp768: Vientos_1_480_Webp ,
        srcWebp1080: Vientos_1_768_Webp,
        srcPng: Vientos_1_768_Png,
        srcPng320: Vientos_1_320_Png,
        srcPng480: Vientos_1_480_Png,
        srcPng768: Vientos_1_480_Png ,
        srcPng1080: Vientos_1_768_Png,
        srcJpg: Vientos_1_768_Jpg,
        srcJpg320: Vientos_1_320_Jpg,
        srcJpg480: Vientos_1_480_Jpg,
        srcJpg768: Vientos_1_480_Jpg ,
        srcJpg1080: Vientos_1_768_Jpg,
        link: 'https://www.behance.net/gallery/61224283/Diettica-Vientos-Verdes',
    },
    /* novocel identidad, el litoral, scanwater identidad, tesis hacer mockup libro*/
]

export default graphicDesign;