<template>
    <div class="swiper">
        <Swiper 
        :slidesPerView="'auto'"
        :spaceBetween="0"
        :modules="modules" 
        :loop="true" 
        :speed="8000" 
        :autoplay="{
            delay: 0,
            disableOnInteraction: true,
        }"
        :class="{ 'white': right }"
        class="mySwiper m-0">
            <template v-for="(word, index) in words" :key="index">
                <SwiperSlide>
                    <div class="word" :class="{ 'white': right }">
                        {{ word }}<span v-if="right">&gt;</span>
                        <span v-else>&lt;</span>
                    </div> 
                </SwiperSlide>
            </template>
        </Swiper>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/free-mode';
import { FreeMode, Autoplay } from 'swiper/modules';

export default {
    name: 'SwiperRow',
    components: {
        Swiper, SwiperSlide,
    },
    setup() {
        return {
            modules: [FreeMode, Autoplay],
        };
    },
    props:  {
        right: {
            type: Boolean,
        },
    },
    data() {
        return {
            words: ['Argentina', 'Treintañera', 'Diseñadora', 'Desarrolladora', 'Fotógrafa', 'Docente', 'Vegetariana' ],
        }
    },
}
</script>

<style scoped>
.swiper {
    width: 104vw;
    position: relative;
    right: 4.85vw;
}
.swiper-slide {
    max-width: fit-content;
}
.word{
    font-family: var(--font-monospace);
    text-transform: uppercase;
    font-weight: bold;
    color: var(--yellow);
}
.word.white{
    font-weight: normal;
    color: var(--black);
    /* color: transparent; */
    transform: scaleX(-1);
}
.word span{
    position: relative;
    font-weight: bold;
    padding: 3.5vh;
    top: 1.17vh;
}
.mySwiper.white{
    right: 0;
}

@media only screen and (orientation : portrait){
    .word{
        font-size: 16vh;
        line-height: 15vh;
    }
    .word.white{
        -webkit-text-stroke: 1px var(--white);
        margin-bottom: -10px;
    }
}
@media only screen and (orientation : landscape){
    .word{
        font-size: 20vh; /* 10vw; */
        line-height: 18vh /* 9vw */;
    }
    .word.white{
        -webkit-text-stroke: 1.5px var(--white);
    }
}
</style>
<style>
/* .swiper-wrapper{
    left: 2vw;
} */
.swiper-slide{
    min-width: fit-content;
}
</style>
