<template>
    <section id="contact">
        <AccordionItem 
        id="19"
        sectionId="DesignContact"
        sectionName="Contacto"
        spanName="Contacto"
        :clickedFunction="animationItems" >
            <ContactInfo />
        </AccordionItem>
    </section>
</template>

<script>
import AccordionItem from '@/components/AccordionItem.vue';
import ContactInfo from './ContactInfo.vue';

export default {
    name: 'DesignContact',
    components: {
        AccordionItem, ContactInfo,
    },
    data() {
        return {
            clicks: 0,
        }
    },
    methods: {        
        animationItems() {       
            const button = document.getElementById("contact-statue");
            if (this.clicks % 2 === 0) {
                button.classList.remove("animate__slideOutDown");
                button.classList.add("animate__slideInUp");
            } else { 
                button.classList.add("animate__slideOutDown");
                button.classList.remove("animate__slideInUp");
            }
            this.clicks++;
        },
    },
}
</script>

<style scoped>
section{
    padding: 0 5vw 4vw;
}
.container{
    padding-bottom: 2vw;
}
</style>