import Kausana_1_320_Jpg from '@/assets/img/frontend/kausana/jpg/kausana-1_320.jpg';
import Kausana_1_480_Jpg from '@/assets/img/frontend/kausana/jpg/kausana-1_480.jpg';
import Kausana_1_768_Jpg from '@/assets/img/frontend/kausana/jpg/kausana-1_768.jpg';
import Kausana_1_320_Png from '@/assets/img/frontend/kausana/png/kausana-1_320.png';
import Kausana_1_480_Png from '@/assets/img/frontend/kausana/png/kausana-1_480.png';
import Kausana_1_768_Png from '@/assets/img/frontend/kausana/png/kausana-1_768.png';
import Kausana_1_320_Avif from '@/assets/img/frontend/kausana/avif/kausana-1_320.avif';
import Kausana_1_480_Avif from '@/assets/img/frontend/kausana/avif/kausana-1_480.avif';
import Kausana_1_768_Avif from '@/assets/img/frontend/kausana/avif/kausana-1_768.avif';
import Kausana_1_320_Webp from '@/assets/img/frontend/kausana/webp/kausana-1_320.webp';
import Kausana_1_480_Webp from '@/assets/img/frontend/kausana/webp/kausana-1_480.webp';
import Kausana_1_768_Webp from '@/assets/img/frontend/kausana/webp/kausana-1_768.webp';

import Novocel_1_320_Jpg from '@/assets/img/frontend/novocel/jpg/novocel-1_320.jpg';
import Novocel_1_480_Jpg from '@/assets/img/frontend/novocel/jpg/novocel-1_480.jpg';
import Novocel_1_768_Jpg from '@/assets/img/frontend/novocel/jpg/novocel-1_768.jpg';
import Novocel_1_320_Png from '@/assets/img/frontend/novocel/png/novocel-1_320.png';
import Novocel_1_480_Png from '@/assets/img/frontend/novocel/png/novocel-1_480.png';
import Novocel_1_768_Png from '@/assets/img/frontend/novocel/png/novocel-1_768.png';
import Novocel_1_320_Avif from '@/assets/img/frontend/novocel/avif/novocel-1_320.avif';
import Novocel_1_480_Avif from '@/assets/img/frontend/novocel/avif/novocel-1_480.avif';
import Novocel_1_768_Avif from '@/assets/img/frontend/novocel/avif/novocel-1_768.avif';
import Novocel_1_320_Webp from '@/assets/img/frontend/novocel/webp/novocel-1_320.webp';
import Novocel_1_480_Webp from '@/assets/img/frontend/novocel/webp/novocel-1_480.webp';
import Novocel_1_768_Webp from '@/assets/img/frontend/novocel/webp/novocel-1_768.webp';

import Kaumon_1_320_Jpg from '@/assets/img/frontend/kaumon/jpg/kaumon-1_320.jpg';
import Kaumon_1_480_Jpg from '@/assets/img/frontend/kaumon/jpg/kaumon-1_480.jpg';
import Kaumon_1_768_Jpg from '@/assets/img/frontend/kaumon/jpg/kaumon-1_768.jpg';
import Kaumon_1_320_Png from '@/assets/img/frontend/kaumon/png/kaumon-1_320.png';
import Kaumon_1_480_Png from '@/assets/img/frontend/kaumon/png/kaumon-1_480.png';
import Kaumon_1_768_Png from '@/assets/img/frontend/kaumon/png/kaumon-1_768.png';
import Kaumon_1_320_Avif from '@/assets/img/frontend/kaumon/avif/kaumon-1_320.avif';
import Kaumon_1_480_Avif from '@/assets/img/frontend/kaumon/avif/kaumon-1_480.avif';
import Kaumon_1_768_Avif from '@/assets/img/frontend/kaumon/avif/kaumon-1_768.avif';
import Kaumon_1_320_Webp from '@/assets/img/frontend/kaumon/webp/kaumon-1_320.webp';
import Kaumon_1_480_Webp from '@/assets/img/frontend/kaumon/webp/kaumon-1_480.webp';
import Kaumon_1_768_Webp from '@/assets/img/frontend/kaumon/webp/kaumon-1_768.webp';

import Hello_1_320_Jpg from '@/assets/img/frontend/helloword/jpg/hello-1_320.jpg';
import Hello_1_480_Jpg from '@/assets/img/frontend/helloword/jpg/hello-1_480.jpg';
import Hello_1_768_Jpg from '@/assets/img/frontend/helloword/jpg/hello-1_768.jpg';
import Hello_1_320_Png from '@/assets/img/frontend/helloword/png/hello-1_320.png';
import Hello_1_480_Png from '@/assets/img/frontend/helloword/png/hello-1_480.png';
import Hello_1_768_Png from '@/assets/img/frontend/helloword/png/hello-1_768.png';
import Hello_1_320_Avif from '@/assets/img/frontend/helloword/avif/hello-1_320.avif';
import Hello_1_480_Avif from '@/assets/img/frontend/helloword/avif/hello-1_480.avif';
import Hello_1_768_Avif from '@/assets/img/frontend/helloword/avif/hello-1_768.avif';
import Hello_1_320_Webp from '@/assets/img/frontend/helloword/webp/hello-1_320.webp';
import Hello_1_480_Webp from '@/assets/img/frontend/helloword/webp/hello-1_480.webp';
import Hello_1_768_Webp from '@/assets/img/frontend/helloword/webp/hello-1_768.webp';

import Llave_1_320_Jpg from '@/assets/img/frontend/lallave/jpg/lallave-1_320.jpg';
import Llave_1_480_Jpg from '@/assets/img/frontend/lallave/jpg/lallave-1_480.jpg';
import Llave_1_768_Jpg from '@/assets/img/frontend/lallave/jpg/lallave-1_768.jpg';
import Llave_1_320_Png from '@/assets/img/frontend/lallave/png/lallave-1_320.png';
import Llave_1_480_Png from '@/assets/img/frontend/lallave/png/lallave-1_480.png';
import Llave_1_768_Png from '@/assets/img/frontend/lallave/png/lallave-1_768.png';
import Llave_1_320_Avif from '@/assets/img/frontend/lallave/avif/lallave-1_320.avif';
import Llave_1_480_Avif from '@/assets/img/frontend/lallave/avif/lallave-1_480.avif';
import Llave_1_768_Avif from '@/assets/img/frontend/lallave/avif/lallave-1_768.avif';
import Llave_1_320_Webp from '@/assets/img/frontend/lallave/webp/lallave-1_320.webp';
import Llave_1_480_Webp from '@/assets/img/frontend/lallave/webp/lallave-1_480.webp';
import Llave_1_768_Webp from '@/assets/img/frontend/lallave/webp/lallave-1_768.webp';

import Hello_2_320_Jpg from '@/assets/img/frontend/hello/jpg/hello-1_320.jpg';
import Hello_2_480_Jpg from '@/assets/img/frontend/hello/jpg/hello-1_480.jpg';
import Hello_2_768_Jpg from '@/assets/img/frontend/hello/jpg/hello-1_768.jpg';
import Hello_2_320_Png from '@/assets/img/frontend/hello/png/hello-1_320.png';
import Hello_2_480_Png from '@/assets/img/frontend/hello/png/hello-1_480.png';
import Hello_2_768_Png from '@/assets/img/frontend/hello/png/hello-1_768.png';
import Hello_2_320_Avif from '@/assets/img/frontend/hello/avif/hello-1_320.avif';
import Hello_2_480_Avif from '@/assets/img/frontend/hello/avif/hello-1_480.avif';
import Hello_2_768_Avif from '@/assets/img/frontend/hello/avif/hello-1_768.avif';
import Hello_2_320_Webp from '@/assets/img/frontend/hello/webp/hello-1_320.webp';
import Hello_2_480_Webp from '@/assets/img/frontend/hello/webp/hello-1_480.webp';
import Hello_2_768_Webp from '@/assets/img/frontend/hello/webp/hello-1_768.webp';

import Gifos_1_320_Jpg from '@/assets/img/frontend/gifos/jpg/gifos-1_320.jpg';
import Gifos_1_480_Jpg from '@/assets/img/frontend/gifos/jpg/gifos-1_480.jpg';
import Gifos_1_768_Jpg from '@/assets/img/frontend/gifos/jpg/gifos-1_768.jpg';
import Gifos_1_320_Png from '@/assets/img/frontend/gifos/png/gifos-1_320.png';
import Gifos_1_480_Png from '@/assets/img/frontend/gifos/png/gifos-1_480.png';
import Gifos_1_768_Png from '@/assets/img/frontend/gifos/png/gifos-1_768.png';
import Gifos_1_320_Avif from '@/assets/img/frontend/gifos/avif/gifos-1_320.avif';
import Gifos_1_480_Avif from '@/assets/img/frontend/gifos/avif/gifos-1_480.avif';
import Gifos_1_768_Avif from '@/assets/img/frontend/gifos/avif/gifos-1_768.avif';
import Gifos_1_320_Webp from '@/assets/img/frontend/gifos/webp/gifos-1_320.webp';
import Gifos_1_480_Webp from '@/assets/img/frontend/gifos/webp/gifos-1_480.webp';
import Gifos_1_768_Webp from '@/assets/img/frontend/gifos/webp/gifos-1_768.webp';

import Alalata_1_320_Jpg from '@/assets/img/frontend/alalata/jpg/alalata-1_320.jpg';
import Alalata_1_480_Jpg from '@/assets/img/frontend/alalata/jpg/alalata-1_480.jpg';
import Alalata_1_768_Jpg from '@/assets/img/frontend/alalata/jpg/alalata-1_768.jpg';
import Alalata_1_320_Png from '@/assets/img/frontend/alalata/png/alalata-1_320.png';
import Alalata_1_480_Png from '@/assets/img/frontend/alalata/png/alalata-1_480.png';
import Alalata_1_768_Png from '@/assets/img/frontend/alalata/png/alalata-1_768.png';
import Alalata_1_320_Avif from '@/assets/img/frontend/alalata/avif/alalata-1_320.avif';
import Alalata_1_480_Avif from '@/assets/img/frontend/alalata/avif/alalata-1_480.avif';
import Alalata_1_768_Avif from '@/assets/img/frontend/alalata/avif/alalata-1_768.avif';
import Alalata_1_320_Webp from '@/assets/img/frontend/alalata/webp/alalata-1_320.webp';
import Alalata_1_480_Webp from '@/assets/img/frontend/alalata/webp/alalata-1_480.webp';
import Alalata_1_768_Webp from '@/assets/img/frontend/alalata/webp/alalata-1_768.webp';

import Kforms_1_320_Jpg from '@/assets/img/frontend/kforms/jpg/kforms-1_320.jpg';
import Kforms_1_480_Jpg from '@/assets/img/frontend/kforms/jpg/kforms-1_480.jpg';
import Kforms_1_768_Jpg from '@/assets/img/frontend/kforms/jpg/kforms-1_768.jpg';
import Kforms_1_320_Png from '@/assets/img/frontend/kforms/png/kforms-1_320.png';
import Kforms_1_480_Png from '@/assets/img/frontend/kforms/png/kforms-1_480.png';
import Kforms_1_768_Png from '@/assets/img/frontend/kforms/png/kforms-1_768.png';
import Kforms_1_320_Avif from '@/assets/img/frontend/kforms/avif/kforms-1_320.avif';
import Kforms_1_480_Avif from '@/assets/img/frontend/kforms/avif/kforms-1_480.avif';
import Kforms_1_768_Avif from '@/assets/img/frontend/kforms/avif/kforms-1_768.avif';
import Kforms_1_320_Webp from '@/assets/img/frontend/kforms/webp/kforms-1_320.webp';
import Kforms_1_480_Webp from '@/assets/img/frontend/kforms/webp/kforms-1_480.webp';
import Kforms_1_768_Webp from '@/assets/img/frontend/kforms/webp/kforms-1_768.webp';

import TecnoNews_1_320_Jpg from '@/assets/img/frontend/tecnonews/jpg/tecnonews-1_320.jpg';
import TecnoNews_1_480_Jpg from '@/assets/img/frontend/tecnonews/jpg/tecnonews-1_480.jpg';
import TecnoNews_1_768_Jpg from '@/assets/img/frontend/tecnonews/jpg/tecnonews-1_768.jpg';
import TecnoNews_1_320_Png from '@/assets/img/frontend/tecnonews/png/tecnonews-1_320.png';
import TecnoNews_1_480_Png from '@/assets/img/frontend/tecnonews/png/tecnonews-1_480.png';
import TecnoNews_1_768_Png from '@/assets/img/frontend/tecnonews/png/tecnonews-1_768.png';
import TecnoNews_1_320_Avif from '@/assets/img/frontend/tecnonews/avif/tecnonews-1_320.avif';
import TecnoNews_1_480_Avif from '@/assets/img/frontend/tecnonews/avif/tecnonews-1_480.avif';
import TecnoNews_1_768_Avif from '@/assets/img/frontend/tecnonews/avif/tecnonews-1_768.avif';
import TecnoNews_1_320_Webp from '@/assets/img/frontend/tecnonews/webp/tecnonews-1_320.webp';
import TecnoNews_1_480_Webp from '@/assets/img/frontend/tecnonews/webp/tecnonews-1_480.webp';
import TecnoNews_1_768_Webp from '@/assets/img/frontend/tecnonews/webp/tecnonews-1_768.webp';

let frontend = [
    {
        imageId: 'kausana-1',
        altImg: 'Pantalla de la web oficial de «Kausana».',
        description: `Diseño y desarrollo de web oficial de <span class="bold">«Kausana»</span>, empresa de Desarrollo de Software.`,
        srcAvif: Kausana_1_768_Avif,
        srcAvif320: Kausana_1_320_Avif,
        srcAvif480: Kausana_1_480_Avif,
        srcAvif768: Kausana_1_480_Avif ,
        srcAvif1080: Kausana_1_768_Avif,
        srcWebp: Kausana_1_768_Webp,
        srcWebp320: Kausana_1_320_Webp,
        srcWebp480: Kausana_1_480_Webp,
        srcWebp768: Kausana_1_480_Webp ,
        srcWebp1080: Kausana_1_768_Webp,
        srcPng: Kausana_1_768_Png,
        srcPng320: Kausana_1_320_Png,
        srcPng480: Kausana_1_480_Png,
        srcPng768: Kausana_1_480_Png ,
        srcPng1080: Kausana_1_768_Png,
        srcJpg: Kausana_1_768_Jpg,
        srcJpg320: Kausana_1_320_Jpg,
        srcJpg480: Kausana_1_480_Jpg,
        srcJpg768: Kausana_1_480_Jpg ,
        srcJpg1080: Kausana_1_768_Jpg,
        link: 'https://kausana.cl/',
        txtLink: 'Ir a Kausana',
    },
    {
        imageId: 'novocel-1',
        altImg: 'Pantalla de la web oficial de «Novocel».',
        description: `Desarrollo de web oficial de <span class="bold">«Novocel»</span>, clínica de medicina regenerativa.`,
        srcAvif: Novocel_1_768_Avif,
        srcAvif320: Novocel_1_320_Avif,
        srcAvif480: Novocel_1_480_Avif,
        srcAvif768: Novocel_1_480_Avif ,
        srcAvif1080: Novocel_1_768_Avif,
        srcWebp: Novocel_1_768_Webp,
        srcWebp320: Novocel_1_320_Webp,
        srcWebp480: Novocel_1_480_Webp,
        srcWebp768: Novocel_1_480_Webp ,
        srcWebp1080: Novocel_1_768_Webp,
        srcPng: Novocel_1_768_Png,
        srcPng320: Novocel_1_320_Png,
        srcPng480: Novocel_1_480_Png,
        srcPng768: Novocel_1_480_Png ,
        srcPng1080: Novocel_1_768_Png,
        srcJpg: Novocel_1_768_Jpg,
        srcJpg320: Novocel_1_320_Jpg,
        srcJpg480: Novocel_1_480_Jpg,
        srcJpg768: Novocel_1_480_Jpg ,
        srcJpg1080: Novocel_1_768_Jpg,
        link: 'https://celulas.clinicanovocel.cl/',
        txtLink: 'Ir a Clinica Novocel',
    },
    {
        imageId: 'kaumon-1',
        altImg: 'Pantalla de la web oficial de «Kaumon».',
        description: `Desarrollo de web oficial de <span class="bold">«Kaumon»</span>, herramienta para gestión logística y seguridad conductual.`,
        srcAvif: Kaumon_1_768_Avif,
        srcAvif320: Kaumon_1_320_Avif,
        srcAvif480: Kaumon_1_480_Avif,
        srcAvif768: Kaumon_1_480_Avif ,
        srcAvif1080: Kaumon_1_768_Avif,
        srcWebp: Kaumon_1_768_Webp,
        srcWebp320: Kaumon_1_320_Webp,
        srcWebp480: Kaumon_1_480_Webp,
        srcWebp768: Kaumon_1_480_Webp ,
        srcWebp1080: Kaumon_1_768_Webp,
        srcPng: Kaumon_1_768_Png,
        srcPng320: Kaumon_1_320_Png,
        srcPng480: Kaumon_1_480_Png,
        srcPng768: Kaumon_1_480_Png ,
        srcPng1080: Kaumon_1_768_Png,
        srcJpg: Kaumon_1_768_Jpg,
        srcJpg320: Kaumon_1_320_Jpg,
        srcJpg480: Kaumon_1_480_Jpg,
        srcJpg768: Kaumon_1_480_Jpg ,
        srcJpg1080: Kaumon_1_768_Jpg,
        link: 'https://kaumon.cl/',
        txtLink: 'Ir a Kaumon',
    },
    {
        imageId: 'hello-1',
        altImg: 'Primer diseño de la web personal',
        description: `Diseño y desarrollo de mi anterior página web personal, titulada <span class="bold">«Hello World!»</span>.`,
        srcAvif: Hello_1_768_Avif,
        srcAvif320: Hello_1_320_Avif,
        srcAvif480: Hello_1_480_Avif,
        srcAvif768: Hello_1_480_Avif ,
        srcAvif1080: Hello_1_768_Avif,
        srcWebp: Hello_1_768_Webp,
        srcWebp320: Hello_1_320_Webp,
        srcWebp480: Hello_1_480_Webp,
        srcWebp768: Hello_1_480_Webp ,
        srcWebp1080: Hello_1_768_Webp,
        srcPng: Hello_1_768_Png,
        srcPng320: Hello_1_320_Png,
        srcPng480: Hello_1_480_Png,
        srcPng768: Hello_1_480_Png ,
        srcPng1080: Hello_1_768_Png,
        srcJpg: Hello_1_768_Jpg,
        srcJpg320: Hello_1_320_Jpg,
        srcJpg480: Hello_1_480_Jpg,
        srcJpg768: Hello_1_480_Jpg ,
        srcJpg1080: Hello_1_768_Jpg,
        link: 'https://ceciliadepaula.github.io/helloworld/',
        txtLink: 'Ir a Hello World!',
    },
    {
        imageId: 'gifos-1',
        altImg: 'Desarrollo de «Gifos»',
        description: `Desarrollo de <span class="bold">«Gifos»</span>, aplicación web interactiva para compartir GIFs animados.`,
        srcAvif: Gifos_1_768_Avif,
        srcAvif320: Gifos_1_320_Avif,
        srcAvif480: Gifos_1_480_Avif,
        srcAvif768: Gifos_1_480_Avif ,
        srcAvif1080: Gifos_1_768_Avif,
        srcWebp: Gifos_1_768_Webp,
        srcWebp320: Gifos_1_320_Webp,
        srcWebp480: Gifos_1_480_Webp,
        srcWebp768: Gifos_1_480_Webp ,
        srcWebp1080: Gifos_1_768_Webp,
        srcPng: Gifos_1_768_Png,
        srcPng320: Gifos_1_320_Png,
        srcPng480: Gifos_1_480_Png,
        srcPng768: Gifos_1_480_Png ,
        srcPng1080: Gifos_1_768_Png,
        srcJpg: Gifos_1_768_Jpg,
        srcJpg320: Gifos_1_320_Jpg,
        srcJpg480: Gifos_1_480_Jpg,
        srcJpg768: Gifos_1_480_Jpg ,
        srcJpg1080: Gifos_1_768_Jpg,
        link: 'https://ceciliadepaula.github.io/gifos/',
        txtLink: 'Ir a Gifos',
    },
    {
        imageId: 'hello-2',
        altImg: 'Desarrollo de «Hello world pale blue dot»',
        description: `Desarrollo de <span class="bold">«Hello world pale blue dot»</span>, un podcast channel.`,
        srcAvif: Hello_2_768_Avif,
        srcAvif320: Hello_2_320_Avif,
        srcAvif480: Hello_2_480_Avif,
        srcAvif768: Hello_2_480_Avif ,
        srcAvif1080: Hello_2_768_Avif,
        srcWebp: Hello_2_768_Webp,
        srcWebp320: Hello_2_320_Webp,
        srcWebp480: Hello_2_480_Webp,
        srcWebp768: Hello_2_480_Webp ,
        srcWebp1080: Hello_2_768_Webp,
        srcPng: Hello_2_768_Png,
        srcPng320: Hello_2_320_Png,
        srcPng480: Hello_2_480_Png,
        srcPng768: Hello_2_480_Png ,
        srcPng1080: Hello_2_768_Png,
        srcJpg: Hello_2_768_Jpg,
        srcJpg320: Hello_2_320_Jpg,
        srcJpg480: Hello_2_480_Jpg,
        srcJpg768: Hello_2_480_Jpg ,
        srcJpg1080: Hello_2_768_Jpg,
        link: 'https://ceciliadepaula.github.io/podcast_Hello_pale_blue_dot/',
        txtLink: 'Ir a Hello world pale blue dot',
    },
    {
        imageId: 'la-llave-1',
        altImg: 'Desarrollo de «La llave»',
        description: `Desarrollo de <span class="bold">«La Llave»</span>, presentación de proyecto de Tesina de Lic. en Diseño de la Comunicación Visual.`,
        srcAvif: Llave_1_768_Avif,
        srcAvif320: Llave_1_320_Avif,
        srcAvif480: Llave_1_480_Avif,
        srcAvif768: Llave_1_480_Avif ,
        srcAvif1080: Llave_1_768_Avif,
        srcWebp: Llave_1_768_Webp,
        srcWebp320: Llave_1_320_Webp,
        srcWebp480: Llave_1_480_Webp,
        srcWebp768: Llave_1_480_Webp ,
        srcWebp1080: Llave_1_768_Webp,
        srcPng: Llave_1_768_Png,
        srcPng320: Llave_1_320_Png,
        srcPng480: Llave_1_480_Png,
        srcPng768: Llave_1_480_Png ,
        srcPng1080: Llave_1_768_Png,
        srcJpg: Llave_1_768_Jpg,
        srcJpg320: Llave_1_320_Jpg,
        srcJpg480: Llave_1_480_Jpg,
        srcJpg768: Llave_1_480_Jpg ,
        srcJpg1080: Llave_1_768_Jpg,
        link: 'https://github.com/ceciliadepaula/lallave', 
        txtLink: 'Ir a La Llave',
    },
    {
        imageId: 'kforms-1',
        altImg: 'Desarrollo de «Kforms»',
        description: `Desarrollo de <span class="bold">«Kforms»</span>, sistema de formularios de Kausana.`,
        srcAvif: Kforms_1_768_Avif,
        srcAvif320: Kforms_1_320_Avif,
        srcAvif480: Kforms_1_480_Avif,
        srcAvif768: Kforms_1_480_Avif ,
        srcAvif1080: Kforms_1_768_Avif,
        srcWebp: Kforms_1_768_Webp,
        srcWebp320: Kforms_1_320_Webp,
        srcWebp480: Kforms_1_480_Webp,
        srcWebp768: Kforms_1_480_Webp ,
        srcWebp1080: Kforms_1_768_Webp,
        srcPng: Kforms_1_768_Png,
        srcPng320: Kforms_1_320_Png,
        srcPng480: Kforms_1_480_Png,
        srcPng768: Kforms_1_480_Png ,
        srcPng1080: Kforms_1_768_Png,
        srcJpg: Kforms_1_768_Jpg,
        srcJpg320: Kforms_1_320_Jpg,
        srcJpg480: Kforms_1_480_Jpg,
        srcJpg768: Kforms_1_480_Jpg ,
        srcJpg1080: Kforms_1_768_Jpg,
        link: 'https://github.com/ceciliadepaula/kforms/',
        txtLink: 'Ir a Kforms',
    },
    {
        imageId: 'alalata-1',
        altImg: 'Desarrollo de «Alalata»',
        description: `Diseño y desarrollo de <span class="bold">«Alalata»</span>, taller de fotografía estenopeica.`,
        srcAvif: Alalata_1_768_Avif,
        srcAvif320: Alalata_1_320_Avif,
        srcAvif480: Alalata_1_480_Avif,
        srcAvif768: Alalata_1_480_Avif ,
        srcAvif1080: Alalata_1_768_Avif,
        srcWebp: Alalata_1_768_Webp,
        srcWebp320: Alalata_1_320_Webp,
        srcWebp480: Alalata_1_480_Webp,
        srcWebp768: Alalata_1_480_Webp ,
        srcWebp1080: Alalata_1_768_Webp,
        srcPng: Alalata_1_768_Png,
        srcPng320: Alalata_1_320_Png,
        srcPng480: Alalata_1_480_Png,
        srcPng768: Alalata_1_480_Png ,
        srcPng1080: Alalata_1_768_Png,
        srcJpg: Alalata_1_768_Jpg,
        srcJpg320: Alalata_1_320_Jpg,
        srcJpg480: Alalata_1_480_Jpg,
        srcJpg768: Alalata_1_480_Jpg ,
        srcJpg1080: Alalata_1_768_Jpg,
        link: 'https://ceciliadepaula.github.io/alalata/', 
        txtLink: 'Ir a Alalata',
    },
    {
        imageId: 'tecnonews-1',
        altImg: 'Desarrollo de «TecnoNews»',
        description: `Diseño y desarrollo de <span class="bold">«TecnoNews»</span>, portal de noticias tecnológicas.`,
        srcAvif: TecnoNews_1_768_Avif,
        srcAvif320: TecnoNews_1_320_Avif,
        srcAvif480: TecnoNews_1_480_Avif,
        srcAvif768: TecnoNews_1_480_Avif ,
        srcAvif1080: TecnoNews_1_768_Avif,
        srcWebp: TecnoNews_1_768_Webp,
        srcWebp320: TecnoNews_1_320_Webp,
        srcWebp480: TecnoNews_1_480_Webp,
        srcWebp768: TecnoNews_1_480_Webp ,
        srcWebp1080: TecnoNews_1_768_Webp,
        srcPng: TecnoNews_1_768_Png,
        srcPng320: TecnoNews_1_320_Png,
        srcPng480: TecnoNews_1_480_Png,
        srcPng768: TecnoNews_1_480_Png ,
        srcPng1080: TecnoNews_1_768_Png,
        srcJpg: TecnoNews_1_768_Jpg,
        srcJpg320: TecnoNews_1_320_Jpg,
        srcJpg480: TecnoNews_1_480_Jpg,
        srcJpg768: TecnoNews_1_480_Jpg ,
        srcJpg1080: TecnoNews_1_768_Jpg,
        link: 'https://ceciliadepaula.github.io/tecnonews/',
        txtLink: 'Ir a TecnoNews',
    },


    /* 
    portal wisecity / novocel / mintral / tempora 
    scanwater / scanmineral
    ccu
    tempora web?
    medlog web?
    tecnoera
    netexplota diseño web, mailing
    */ 
]

export default frontend;