<template>
    <section id="design-presentation">
        <!-- <PictureStructure 
        :srcAvif="TextureAvif" 
        :srcPng="TexturePng" 
        :srcWebp="TextureWebp" 
        altImg="Textura pared con manchas"
        classes="presentation-texture"
        class="overflow-texture"
        /> -->
        <BinaryCode class="binary"/>
        <DesignerName />
        <div class="statue-container">
            <PictureStructure 
            :srcAvif="StatueAvif"
            :srcAvif320="StatueAvif320"
            :srcAvif480="StatueAvif480"
            :srcAvif768="StatueAvif768"
            :srcAvif1080="StatueAvif1080"
            :srcWebp="StatueWebp"
            :srcWebp320="StatueWebp320"
            :srcWebp480="StatueWebp480"
            :srcWebp768="StatueWebp768"
            :srcWebp1080="StatueWebp1080"
            :srcPng="StatuePng"
            :srcPng320="StatuePng320"
            :srcPng480="StatuePng480"
            :srcPng768="StatuePng768"
            :srcPng1080="StatuePng1080"
            altImg="Estatua de estilo renacentista de una mujer usando un celular"
            classes="presentation-statue"
            />
        </div>
    </section>
</template>

<script>
import PictureStructure from '@/components/PictureStructure.vue';
import DesignerName from './DesignerName.vue';
import BinaryCode from './BinaryCode.vue';
import StatueAvif from '@/assets/img/statues/avif/presentation_statue.avif';
import StatueAvif320 from '@/assets/img/statues/avif/presentation_statue_320.avif';
import StatueAvif480 from '@/assets/img/statues/avif/presentation_statue_480.avif';
import StatueAvif768 from '@/assets/img/statues/avif/presentation_statue_768.avif';
import StatueAvif1080 from '@/assets/img/statues/avif/presentation_statue_1080.avif';
import StatuePng from '@/assets/img/statues/png/presentation_statue.png';
import StatuePng320 from '@/assets/img/statues/png/presentation_statue_320.png';
import StatuePng480 from '@/assets/img/statues/png/presentation_statue_480.png';
import StatuePng768 from '@/assets/img/statues/png/presentation_statue_768.png';
import StatuePng1080 from '@/assets/img/statues/png/presentation_statue_1080.png';
import StatueWebp from '@/assets/img/statues/webp/presentation_statue.webp';
import StatueWebp320 from '@/assets/img/statues/webp/presentation_statue_320.webp';
import StatueWebp480 from '@/assets/img/statues/webp/presentation_statue_480.webp';
import StatueWebp768 from '@/assets/img/statues/webp/presentation_statue_768.webp';
import StatueWebp1080 from '@/assets/img/statues/webp/presentation_statue_1080.webp';
/* import TextureAvif from '@/assets/img/textures/avif/texture_1.avif';
import TexturePng from '@/assets/img/textures/png/texture_1.png';
import TextureWebp from '@/assets/img/textures/webp/texture_1.webp'; */

export default {
    name: 'DesignPresentation',
    components: {
        PictureStructure,
        DesignerName,
        BinaryCode,
    },
    data() {
        return {
            /* TextureAvif, TexturePng, TextureWebp, */
            StatueAvif, StatueAvif320, StatueAvif480, StatueAvif768, StatueAvif1080,
            StatuePng, StatuePng320, StatuePng480, StatuePng768, StatuePng1080,
            StatueWebp, StatueWebp320, StatueWebp480, StatueWebp768, StatueWebp1080,
        };
    },
    mounted() {
        this.updateScreenSize();
        window.addEventListener('resize', this.updateScreenSize);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.updateScreenSize);
    },
    methods: {
        updateScreenSize() {
            let statue = document.getElementsByClassName("presentation-statue")[0];
            let nameBox = document.getElementById("presentation-name");
            let name = document.getElementById("name");

            if ( window.innerHeight > window.innerWidth ){
                statue.style.width= "94vw";
                name.style.top = "0vw";
                name.style.left = "0vw";
                nameBox.style.top = "0vh";
            } else if( window.innerHeight >= (window.innerWidth * 0.65)){ 
                statue.style.width= "80vh";
                name.style.top = "-17vw";
                name.style.left = "28.3vw";
                nameBox.style.top = "57vh";
            } else if( window.innerHeight >= (window.innerWidth * 0.5)){
                statue.style.width= "80vh";
                statue.style.left= "-5vh";
                name.style.top = "0vw";
                name.style.left = "0vw";
                nameBox.style.top = "45vh";
            } else if (window.innerHeight < (window.innerWidth * 0.4)) {
                statue.style.width= "38vw";
                statue.style.left= "0vw";
                statue.style.top="6.5vw";
                name.style.top = "0vw";
                name.style.left = "0vw";
                nameBox.style.top = "45vh";
            } else {
                statue.style.width= "38vw";
                statue.style.left= "0vw";
                statue.style.top="0vw";
                name.style.top = "0vw";
                name.style.left = "0vw";
                nameBox.style.top = "45vh";
            }                
        },
    },
}
</script>

<style scoped>
section#design-presentation {
    background-color: var(--yellow);
    height: auto;
    width: 100vw;
    overflow: hidden;
}
.statue-container{
    width: 100%;
    height: auto; 
    display: flex;
    align-items: flex-end;
    position: relative;
}
.overflow-texture{
    min-height: 100vh;
    height: auto;
    width: 100vw;
    position: absolute;
    top: 0;
    overflow: hidden;
}
@media only screen and (orientation : portrait){
    section#design-presentation {
        min-height: 205.7vw;
    }
    .statue-container{
        position: absolute;
        top: 92.95vw;
        overflow: hidden;
    }
    .overflow-texture{
        display: none;
    }
}
@media only screen and (orientation : landscape){
    section#design-presentation {
        height: 100vh;
    }
    .statue-container{
        position: absolute;
        top: 0;
        height: 100vh;
        margin-bottom: 0;
    }
    .overflow-texture{
        max-height: 100vh;
        max-width: 100vw;
    }
}
</style>

<style>
.presentation-statue{
    position: relative;
    z-index: 2;
}
.presentation-texture{
    opacity: 0.40;
    mix-blend-mode: luminosity;
}
@media only screen and (orientation : portrait){
    .presentation-statue{
        width: 94vw;
        position: relative;
    }
    .presentation-texture{
        min-height: 100vh;
        height: auto;
    }
}
@media only screen and (orientation : landscape){
    .presentation-statue{
        width: 38vw; 
    }
    .presentation-texture{
        opacity: 0.77;
        min-width: 100vw;
    }
}
</style>