<template>
    <a :href=href :aria-label=text>
        <div class="button" @mouseover="showName" @mouseleave="hiddenName">
            <i :class="icon"></i>
            <p v-if="text">
                {{text}}
            </p>
        </div>
    </a>
</template>

<script>
export default {
    name: 'ButtonIcon',
    props: {
        icon: {
            type: String,
        },
        text: {
            type: String,
        },
        href: {
            type: String,
        },
    },
    methods: {
        showName(event){
            event.target.classList.add("show-word");
        },
        hiddenName(event){
            event.target.classList.remove("show-word");
        }
    }
}
</script>

<style scoped>
a{
    text-decoration: none;
}
.button{
    background-color: var(--white);
    cursor: pointer;
    /* transition: all 0.3s ease-in-out; */
    height: 9.50vw;
    width: 9.50vw;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
i, p {
    opacity: 0.9;
    font-size: 4.4vw;
    padding: 2.55vw;
    color: var(--black);
    /* transition: all 0.3s ease-in-out; */
}
p{
    font-family: var(--font-monospace);
    text-transform: uppercase;
    margin: 0;
    white-space: nowrap; 
    display: none;    
    opacity: 0;
}
.button:hover{
    background-color: var(--black);
}
.button:hover p, .button:hover i{
    color: var(--white);
    opacity: 1;
    width: 100%; 
}
.button.show-word {
    min-width: max-content;
    padding-left: 1vw;
    padding-right: 1vw;
}
.button.show-word p{
    display: inline;
    padding-left: 0.8vw;
}

/* RESPONSIVE */
@media only screen and (orientation : landscape){
    .button{
        height: 2.7vw;
        width: 2.7vw;
    }
    i, p {
        font-size: 1.5vw;
        padding: 0;
    }
}
</style>