<template>
    <div class="speciality-container">
        <div class="decoration">
            <template v-for="(speciality, index) in Specialities" :key="index">
                <div :id="`circle_${index}`" class="circle" :class="speciality.size" @click="clickAction(index)">
                    <p class="title">{{ speciality.circleTitle }}</p>
                    <p class="circle-subtitle" v-if="speciality.circleSubTitle">{{ speciality.circleSubTitle }}</p>
                </div>
            </template>
        </div>
        <div class="information">
            <div v-if="!click" class="no-click">
                <SubtitleSection name="Mis áreas profesionales"/>
                <p class="no-click-text">Selecciona un área para obtener más información al respecto.</p>
            </div>
            <template v-else v-for="(speciality, index) in Specialities" :key="index">
                <div class="clicked" v-if="speciality.clicked">
                    <p class="speciality-subtitle">{{ speciality.title }}</p>
                    <p class="text">{{ speciality.text }}</p>
                </div>                
            </template>
        </div>
    </div>
</template>

<script>
import Specialities from './specialties.js';
import SubtitleSection from '@/components/SubtitleSection.vue';

export default {
    name: 'CVSpecialties',
    components: {
        SubtitleSection,
    },
    props: {
    },
    data() {
        return {
            Specialities,
            click: false,
        }
    },
    methods: {
        clickAction(index) {
            this.click = true;
            let circle = document.getElementsByClassName("circle");
            for (let i = 0; i < this.Specialities.length; i++) {
                this.Specialities[i].clicked = false;
                circle[i].classList.remove('active');
            }
            this.Specialities[index].clicked = !this.Specialities[index].clicked;
            circle[index].classList.add('active');
        },
    },
    
}
</script>

<style scoped>
.decoration{
    width: min-content;
    margin: auto;
}
.circle{
    background-color: var(--yellow);
    border-radius: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    float: left;
    position: relative;
    cursor: pointer;
}
.circle:hover, .circle.active{
    background-color: var(--white);
    z-index: 2;
}
.circle p{
    color: var(--black);
    font-family: var(--font-monospace);
    text-transform: uppercase;
    text-align: center;
    margin: 0;
}
p.text{
    margin: 0;
}
.speciality-container{
    display: flex;
}
.no-click, .clicked{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.clicked{
    align-items: flex-start
}
.title{
    font-weight: bold;
}
.no-click-text{
    text-align: center;
}
@media only screen and (orientation : portrait){
    .speciality-container{
        flex-direction: column;
    }
    .decoration{
        height:  140vw;
    }
    #circle_0{
        left: 0vw;
        top: 28.57vw; 
    }
    #circle_1{
        left: 24vw;
        top: 34vw;
    }
    #circle_2{
        left: -15vw;
        top: -34vw;
    }
    #circle_3{
        left: 23.809vw;
        top: -66vw;
    }
    #circle_4{
        left: 20vw;
        top: -153vw;
    }
    #circle_5{
        left: -23vw;
        top: -173vw;
    }
    #circle_6{
        left: -24vw;
        top: -139vw;
    }
    #circle_7{
        left: -4vw;
        top: -251vw;
    }
    #circle_8{
        left: 1vw;
        top: -175.5vw;
    }
    .big-circle{
        height: 47vw;
        width: 47vw;
        border-radius: 100%;
    }
    .medium-circle{
        height: 32vw;
        width: 32vw;
        border-radius: 100%;
    }
    .small-circle{
        height: 27vw;
        width: 27vw;
        border-radius: 100%;
    }
    .big-circle p{
        font-size: 4vw;
    }
    .medium-circle p{
        font-size: 3.5vw;
    }
    .small-circle p{
        font-size: 3vw;
    }
    .circle-subtitle{
        display: none;
    }
    .information{
        margin-top: 4vh;
    }
}
@media only screen and (orientation : landscape) {
    .speciality-container{
        flex-direction: row;
    }
    .decoration{
        height: 41.667vw;
    }
    .no-click-text{
        max-width: 55%;
    }
    .information{
        width: 45vw;
        padding-left: 5vw;
    }
    .big-circle{
        height: 17.857vw;
        width: 17.857vw;
        border-radius: 100%;
    }
    .medium-circle{
        height: 11.161vw;
        width: 11.161vw;
        border-radius: 100%;
    }
    .small-circle{
        height: 7.44vw;
        width: 7.44vw;
        border-radius: 100%;
    }
    .big-circle p{
        font-size: 3vh;
    }
    .medium-circle p{
        font-size: 2.356vh;
    }
    .small-circle p{
        font-size: 1.619vh;
    }
    #circle_0{
        left: -2.976vw;
        top: 8.928vw; 
    }
    #circle_1{
        left: 14.509vw;
        top: 5.952vw;
    }
    #circle_2{
        left: -8.184vw;
        top: -14.881vw;
    }
    #circle_3{
        left: 8.184vw;
        top: -26.785vw;
    }
    #circle_4{
        left: 2.157vw;
        top: -58.036vw;
    }
    #circle_5{
        left: -10.565vw;
        top: -63.616vw;
    }
    #circle_6{
        left: -11.904vw;
        top: -52.083vw;
    }
    #circle_7{
        left: -12.053vw;
        top: -80.357vw;
    }
    #circle_8{
        left: 22.321vw;
        top: -69.196vw;
    }
}
@media only screen and (orientation : landscape) and (max-width: 1100px){
    .big-circle p{
        font-size: 2vh;
    }
    .medium-circle p{
        font-size: 1.5vh;
    }
    .small-circle p{
        font-size: 1vh;
    }
}
</style>
