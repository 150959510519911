<template>
    <footer>
        <div class="information">
            <p class="bold">Diseño y desarrollo:</p>
            <p class="light">Cecilia De Paula / {{ new Date().getFullYear() }}</p>
        </div>
        <div class="icons"><!-- ver link o route -->
            <!-- <ButtonIcon icon="fa-solid fa-envelope" text="depaulacecilia@gmail.com" href="#"/>
            <ButtonIcon icon="fa-solid fa-phone" text="+56 9 75976975" href="#"/>
            <ButtonIcon icon="fa-brands fa-linkedin-in" text="LinkedIn" href="https://www.linkedin.com/in/cecilia-de-paula-6b2936190/" target="_blank"/>
            <ButtonIcon icon="fa-brands fa-behance" text="Behance" href="https://www.behance.net/ceciliadepaula" target="_blank"/>
            <ButtonIcon icon="fa-brands fa-gitlab" text="GitLab" href="https://gitlab.com/depaulacecilia" target="_blank"/>
            <ButtonIcon icon="fa-brands fa-github" text="GitHub" href="https://github.com/ceciliadepaula" target="_blank"/>
            <ButtonIcon icon="ri-flickr-fill" text="Flickr" href="https://www.flickr.com/photos/visionpropia" target="_blank"/>
            <ButtonIcon icon="ri-instagram-fill" text="Instagram" href="https://www.instagram.com/chechu.mcdp/" target="_blank"/> -->
            <p class="light">Página diseñada con <span class="bold">Adobe XD</span> y desarrolada con <span class="bold">Vue.js</span></p>
        </div>
    </footer>
</template>

<script>
/* import ButtonIcon from '@/components/ButtonIcon.vue'; */

export default {
    name: 'DesignFooter',
    components: {
        /* ButtonIcon, */
    },
}
</script>

<style scoped>
footer{
    background-color: var(--yellow);
    position: absolute;
    z-index: 10;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
}
p{
    margin: 0;
}
p.bold{
    font-weight: bold;
}
@media only screen and (orientation : portrait){
    footer{
        padding: 3vw  5vw;
        margin: auto;
        justify-content: center;
        bottom: -3vh;
    }
    .information{
        text-align: center;
    }
    .icons{
        display: none;
    }
}
@media only screen and (orientation : landscape){
    footer{
        padding: 1.5vw 5vw;
        justify-content: space-between;
    }
    .information{
        text-align: left;
        display: flex;
    }
    p.light{
        padding-left: 0.5rem;
    }
    .icons{
        display: flex;
        justify-content: flex-end;
        gap: 1vw;
    }
}

</style>