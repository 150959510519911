<template>
    <section id="curriculum-vitae">
        <AccordionItem 
        id="1"
        sectionId="Curriculum"
        sectionName="Curriculum Vitae"
        spanName="Curriculum">
            <CVIntroduction />
            <CVSpecialties id="specialties"/>
            <div id="categories">
                <CVExperience />
                <CVSoftware />
            </div>            
        </AccordionItem>
    </section>
</template>

<script>
import AccordionItem from '@/components/AccordionItem.vue';
import CVIntroduction from './CVIntroduction.vue';
import CVSpecialties from './CVSpecialties.vue';
import CVExperience from './CVExperience.vue';
import CVSoftware from './CVSoftware.vue';

export default {
    name: 'DesignCV',
    components: {
        AccordionItem, CVIntroduction, CVSpecialties, CVExperience, CVSoftware,
    },
}
</script>

<style scoped>
section{
    padding: 0 5vw;
}
#specialties{
    margin-top: 5vh;
}
@media only screen and (orientation : portrait){
    #categories{
        margin-top: 10vh;
    }
}
@media only screen and (orientation : landscape){
    #categories{
        margin-top: 20vh;
    }
}
</style>