<template>
    <div class="binary-code">
        <p>
            00100010 01000001 01101100 01100111 01110101 01101110 01101111 01110011 00100000 01110100 01101111 
            01101101 01100001 01101110 00100000 01100101 01110011 01110100 01100101 00100000 01100011 01100001 
            01101101 01101001 01101110 01101111 00101110 00100000 01000001 01101100 01100111 01110101 01101110 
            01101111 01110011 00100000 01110100 01101111 01101101 01100001 01101110 00100000 01100101 01110011 
            01110100 01100101 00100000 01101111 01110100 01110010 01101111 00101110 00100000 01010000 01100101 
            01110010 01101111 00100000 01100101 01101110 00100000 01101100 01101111 00100000 01110001 01110101 
            01100101 00100000 01100001 00100000 01101101 01101001 00100000 01110010 01100101 01110011 01110000 
            01100101 01100011 01110100 01100001 00101100 00100000 01110000 01100101 01110010 01110011 01101111 
            01101110 01100001 01101100 01101101 01100101 01101110 01110100 01100101 00100000 01110000 01110010 
            01100101 01100110 01101001 01100101 01110010 01101111 00100000 01100101 01101100 00100000 01100001 
            01110100 01100001 01101010 01101111 00101110 00100010
        </p>
    </div>
</template>

<script>
export default {
    name: 'BinaryCode',
}
</script>

<style scoped>
.binary-code{
    position: absolute;
    width: 100vw;
}
.binary-code p{
    font-family: var(--font-monospace);
    opacity: 0.6;
}

/* RESPONSIVE */
@media only screen and (orientation : portrait){
    .binary-code{
        /* top: 66.5vh; */
        top: 133vw;
        left: 20vw;
    }
    .binary-code p{
        font-size: 3vw;
    }
}
@media only screen and (orientation : landscape){
    .binary-code{
        top: 0;
        left: 0;
        width: 24vw;
    }
    .binary-code p{
        font-size: 1.025vw;
        opacity: 0.9;
    }
}
</style>
