<template>
    <div id="presentation-name">
        <div class="flex-name">
            <h1 id="name">Cecilia</h1>
            <div class="last-name-container">
                <h1 id="last-name">De Paula</h1>
                <div id="yellow-stripe">
                    <p v-if="isPortrait">Desarrolladora & Diseñadora web</p>
                    <p v-else>Desarrolladora Frontend & Diseñadora gráfica</p>
                </div>
                <div class="presentation-buttons">
                    <ButtonSection classes="presentation" text="Sobre mí" sectionId="about-me"/>
                    <ButtonSection v-if="isPortrait" classes="presentation" text="Curriculum" sectionId="curriculum-vitae"/>
                    <ButtonSection v-else classes="presentation" text="Curriculum vitae" sectionId="curriculum-vitae"/>
                    <ButtonSection classes="presentation" text="Portfolio" sectionId="portfolio"/>
                    <ButtonSection v-if="isPortrait" classes="presentation" text="Contacto" sectionId="contact"/>
                </div>
            </div>
        </div>        
    </div>
</template>

<script>
import ButtonSection from '@/components/ButtonSection.vue';

export default {
    name: 'DesignerName',
    components: {
        ButtonSection,
    },
    data() {
        return {
            isPortrait: window.innerHeight > window.innerWidth,
        };
    },
    mounted() {
        window.addEventListener('resize', this.updateScreenSize);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.updateScreenSize);
    },
    methods: {
        updateScreenSize() {
            this.isPortrait = window.innerHeight > window.innerWidth;
        },
        /* scrollToAboutMe() {
            const aboutMeSection = document.getElementById('about-me');
            aboutMeSection.scrollIntoView({ behavior: 'smooth' });
        } */
    },
}
</script>

<style scoped>
h1{
    color: #000000;
    -webkit-filter: invert(100%);
    filter: invert(100%);
}
.flex-name{
    display: flex;
}
#last-name{
    word-spacing: -0.9vw;
}
#yellow-stripe{
    background-color: var(--yellow);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
#yellow-stripe p{
    font-family: var(--font-monospace);
    text-transform: uppercase;
    margin: 0;
}
.presentation-buttons{
    position: relative;
    z-index: 3;
    display: flex;
}
#name{
    position: relative;
}
/* RESPONSIVE */
@media only screen and (orientation : portrait){
    h1{
        font-size: 56vw;
        line-height: 43vw;
    }
    .flex-name{
        flex-direction: column;
    }
    .last-name-container{
        padding-left: 11vw;
    }
    #presentation-name{
        padding: 4vh 5vw;
    }
    #yellow-stripe{
        height: 6.5vw;
        left: 0vw;
        bottom: 17vw;
    }
    #yellow-stripe p{
        font-size: 4.4vw;
        padding-top: 1vw;
        margin-left: -0.5vw; 
    }
    .presentation-buttons{
        flex-direction: column;
        align-items: flex-end;
        gap: 1.5vw;
        top: -3vw;
        left: 5vw;
    }
}
@media only screen and (orientation : landscape){
    h1{
        font-size: 29vw;
        line-height: 0;
    }
    .flex-name{
        flex-direction: row;
    }
    .last-name-container{
        padding-top: 4.7vw;
        padding-left: 0;
    }
    #presentation-name{
        position: absolute;
        top: 45vh;
        left: 22vw;
        min-width: 75vw;
        padding: 0;
        z-index: 1;
        /* top: calc(40vw / 2); */
    }
    #name{
        padding-right: 1.6vw;
    }
    #yellow-stripe{
        width: 40.07vw;
        top: 3.2vw;
        height: auto;
        left: 0vw;
    }
    #yellow-stripe p{
        font-size: 1.62vw;
        padding: 0;
        margin-left: 0;
    }
    .presentation-buttons{
        top: 9vw;
        margin: 0 0.1vw 0 0.4vw;
        flex-direction: row;
        justify-content: space-between;
        gap: 0;
        align-items: center;
        left: 0;
    }
}
</style>
