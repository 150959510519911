import Popcorn_1_320_Jpg from '@/assets/img/photography/popcorn/jpg/popcorn-1_320.jpg';
import Popcorn_1_480_Jpg from '@/assets/img/photography/popcorn/jpg/popcorn-1_480.jpg';
import Popcorn_1_768_Jpg from '@/assets/img/photography/popcorn/jpg/popcorn-1_768.jpg';
import Popcorn_1_320_Png from '@/assets/img/photography/popcorn/png/popcorn-1_320.png';
import Popcorn_1_480_Png from '@/assets/img/photography/popcorn/png/popcorn-1_480.png';
import Popcorn_1_768_Png from '@/assets/img/photography/popcorn/png/popcorn-1_768.png';
import Popcorn_1_320_Avif from '@/assets/img/photography/popcorn/avif/popcorn-1_320.avif';
import Popcorn_1_480_Avif from '@/assets/img/photography/popcorn/avif/popcorn-1_480.avif';
import Popcorn_1_768_Avif from '@/assets/img/photography/popcorn/avif/popcorn-1_768.avif';
import Popcorn_1_320_Webp from '@/assets/img/photography/popcorn/webp/popcorn-1_320.webp';
import Popcorn_1_480_Webp from '@/assets/img/photography/popcorn/webp/popcorn-1_480.webp';
import Popcorn_1_768_Webp from '@/assets/img/photography/popcorn/webp/popcorn-1_768.webp';

import Pinhole_1_320_Jpg from '@/assets/img/photography/pinhole/jpg/pinhole-2_320.jpg';
import Pinhole_1_480_Jpg from '@/assets/img/photography/pinhole/jpg/pinhole-2_480.jpg';
import Pinhole_1_768_Jpg from '@/assets/img/photography/pinhole/jpg/pinhole-2_768.jpg';
import Pinhole_1_320_Png from '@/assets/img/photography/pinhole/png/pinhole-2_320.png';
import Pinhole_1_480_Png from '@/assets/img/photography/pinhole/png/pinhole-2_480.png';
import Pinhole_1_768_Png from '@/assets/img/photography/pinhole/png/pinhole-2_768.png';
import Pinhole_1_320_Avif from '@/assets/img/photography/pinhole/avif/pinhole-2_320.avif';
import Pinhole_1_480_Avif from '@/assets/img/photography/pinhole/avif/pinhole-2_480.avif';
import Pinhole_1_768_Avif from '@/assets/img/photography/pinhole/avif/pinhole-2_768.avif';
import Pinhole_1_320_Webp from '@/assets/img/photography/pinhole/webp/pinhole-2_320.webp';
import Pinhole_1_480_Webp from '@/assets/img/photography/pinhole/webp/pinhole-2_480.webp';
import Pinhole_1_768_Webp from '@/assets/img/photography/pinhole/webp/pinhole-2_768.webp';

import Rock_1_320_Jpg from '@/assets/img/photography/rock/jpg/fito-1_320.jpg';
import Rock_1_480_Jpg from '@/assets/img/photography/rock/jpg/fito-1_480.jpg';
import Rock_1_768_Jpg from '@/assets/img/photography/rock/jpg/fito-1_768.jpg';
import Rock_1_320_Png from '@/assets/img/photography/rock/png/fito-1_320.png';
import Rock_1_480_Png from '@/assets/img/photography/rock/png/fito-1_480.png';
import Rock_1_768_Png from '@/assets/img/photography/rock/png/fito-1_768.png';
import Rock_1_320_Avif from '@/assets/img/photography/rock/avif/fito-1_320.avif';
import Rock_1_480_Avif from '@/assets/img/photography/rock/avif/fito-1_480.avif';
import Rock_1_768_Avif from '@/assets/img/photography/rock/avif/fito-1_768.avif';
import Rock_1_320_Webp from '@/assets/img/photography/rock/webp/fito-1_320.webp';
import Rock_1_480_Webp from '@/assets/img/photography/rock/webp/fito-1_480.webp';
import Rock_1_768_Webp from '@/assets/img/photography/rock/webp/fito-1_768.webp';

import Ojos_1_320_Jpg from '@/assets/img/photography/ojos/jpg/ojos_320.jpg';
import Ojos_1_480_Jpg from '@/assets/img/photography/ojos/jpg/ojos_480.jpg';
import Ojos_1_768_Jpg from '@/assets/img/photography/ojos/jpg/ojos_768.jpg';
import Ojos_1_320_Png from '@/assets/img/photography/ojos/png/ojos_320.png';
import Ojos_1_480_Png from '@/assets/img/photography/ojos/png/ojos_480.png';
import Ojos_1_768_Png from '@/assets/img/photography/ojos/png/ojos_768.png';
import Ojos_1_320_Avif from '@/assets/img/photography/ojos/avif/ojos_320.avif';
import Ojos_1_480_Avif from '@/assets/img/photography/ojos/avif/ojos_480.avif';
import Ojos_1_768_Avif from '@/assets/img/photography/ojos/avif/ojos_768.avif';
import Ojos_1_320_Webp from '@/assets/img/photography/ojos/webp/ojos_320.webp';
import Ojos_1_480_Webp from '@/assets/img/photography/ojos/webp/ojos_480.webp';
import Ojos_1_768_Webp from '@/assets/img/photography/ojos/webp/ojos_768.webp';

import Lola_1_320_Jpg from '@/assets/img/photography/varias/jpg/lola-1_320.jpg';
import Lola_1_480_Jpg from '@/assets/img/photography/varias/jpg/lola-1_480.jpg';
import Lola_1_768_Jpg from '@/assets/img/photography/varias/jpg/lola-1_768.jpg';
import Lola_1_320_Png from '@/assets/img/photography/varias/png/lola-1_320.png';
import Lola_1_480_Png from '@/assets/img/photography/varias/png/lola-1_480.png';
import Lola_1_768_Png from '@/assets/img/photography/varias/png/lola-1_768.png';
import Lola_1_320_Avif from '@/assets/img/photography/varias/avif/lola-1_320.avif';
import Lola_1_480_Avif from '@/assets/img/photography/varias/avif/lola-1_480.avif';
import Lola_1_768_Avif from '@/assets/img/photography/varias/avif/lola-1_768.avif';
import Lola_1_320_Webp from '@/assets/img/photography/varias/webp/lola-1_320.webp';
import Lola_1_480_Webp from '@/assets/img/photography/varias/webp/lola-1_480.webp';
import Lola_1_768_Webp from '@/assets/img/photography/varias/webp/lola-1_768.webp';

import Remolinos_320_Jpg from '@/assets/img/photography/encuentro/jpg/encuentro-2_320.jpg';
import Remolinos_480_Jpg from '@/assets/img/photography/encuentro/jpg/encuentro-2_480.jpg';
import Remolinos_768_Jpg from '@/assets/img/photography/encuentro/jpg/encuentro-2_768.jpg';
import Remolinos_320_Png from '@/assets/img/photography/encuentro/png/encuentro-2_320.png';
import Remolinos_480_Png from '@/assets/img/photography/encuentro/png/encuentro-2_480.png';
import Remolinos_768_Png from '@/assets/img/photography/encuentro/png/encuentro-2_768.png';
import Remolinos_320_Avif from '@/assets/img/photography/encuentro/avif/encuentro-2_320.avif';
import Remolinos_480_Avif from '@/assets/img/photography/encuentro/avif/encuentro-2_480.avif';
import Remolinos_768_Avif from '@/assets/img/photography/encuentro/avif/encuentro-2_768.avif';
import Remolinos_320_Webp from '@/assets/img/photography/encuentro/webp/encuentro-2_320.webp';
import Remolinos_480_Webp from '@/assets/img/photography/encuentro/webp/encuentro-2_480.webp';
import Remolinos_768_Webp from '@/assets/img/photography/encuentro/webp/encuentro-2_768.webp';

import Encuentro_1_320_Jpg from '@/assets/img/photography/nona/jpg/el-encuentro-nona-1_320.jpg';
import Encuentro_1_480_Jpg from '@/assets/img/photography/nona/jpg/el-encuentro-nona-1_480.jpg';
import Encuentro_1_768_Jpg from '@/assets/img/photography/nona/jpg/el-encuentro-nona-1_768.jpg';
import Encuentro_1_320_Png from '@/assets/img/photography/nona/png/el-encuentro-nona-1_320.png';
import Encuentro_1_480_Png from '@/assets/img/photography/nona/png/el-encuentro-nona-1_480.png';
import Encuentro_1_768_Png from '@/assets/img/photography/nona/png/el-encuentro-nona-1_768.png';
import Encuentro_1_320_Avif from '@/assets/img/photography/nona/avif/el-encuentro-nona-1_320.avif';
import Encuentro_1_480_Avif from '@/assets/img/photography/nona/avif/el-encuentro-nona-1_480.avif';
import Encuentro_1_768_Avif from '@/assets/img/photography/nona/avif/el-encuentro-nona-1_768.avif';
import Encuentro_1_320_Webp from '@/assets/img/photography/nona/webp/el-encuentro-nona-1_320.webp';
import Encuentro_1_480_Webp from '@/assets/img/photography/nona/webp/el-encuentro-nona-1_480.webp';
import Encuentro_1_768_Webp from '@/assets/img/photography/nona/webp/el-encuentro-nona-1_768.webp';

import Sociales_1_320_Jpg from '@/assets/img/photography/sociales/jpg/sociales-1_320.jpg';
import Sociales_1_480_Jpg from '@/assets/img/photography/sociales/jpg/sociales-1_480.jpg';
import Sociales_1_768_Jpg from '@/assets/img/photography/sociales/jpg/sociales-1_768.jpg';
import Sociales_1_320_Png from '@/assets/img/photography/sociales/png/sociales-1_320.png';
import Sociales_1_480_Png from '@/assets/img/photography/sociales/png/sociales-1_480.png';
import Sociales_1_768_Png from '@/assets/img/photography/sociales/png/sociales-1_768.png';
import Sociales_1_320_Avif from '@/assets/img/photography/sociales/avif/sociales-1_320.avif';
import Sociales_1_480_Avif from '@/assets/img/photography/sociales/avif/sociales-1_480.avif';
import Sociales_1_768_Avif from '@/assets/img/photography/sociales/avif/sociales-1_768.avif';
import Sociales_1_320_Webp from '@/assets/img/photography/sociales/webp/sociales-1_320.webp';
import Sociales_1_480_Webp from '@/assets/img/photography/sociales/webp/sociales-1_480.webp';
import Sociales_1_768_Webp from '@/assets/img/photography/sociales/webp/sociales-1_768.webp';


let photography = [
    {
        imageId: 'popcorn-1',
        altImg: 'Fotografía titulada «Pop.corn»',
        description: `<span class="bold">«Pop.Corn»</span> es una obra premiada que explora la morfología de diferentes palomitas de maíz.`,
        srcAvif: Popcorn_1_768_Avif,
        srcAvif320: Popcorn_1_320_Avif,
        srcAvif480: Popcorn_1_480_Avif,
        srcAvif768: Popcorn_1_480_Avif ,
        srcAvif1080: Popcorn_1_768_Avif,
        srcWebp: Popcorn_1_768_Webp,
        srcWebp320: Popcorn_1_320_Webp,
        srcWebp480: Popcorn_1_480_Webp,
        srcWebp768: Popcorn_1_480_Webp ,
        srcWebp1080: Popcorn_1_768_Webp,
        srcPng: Popcorn_1_768_Png,
        srcPng320: Popcorn_1_320_Png,
        srcPng480: Popcorn_1_480_Png,
        srcPng768: Popcorn_1_480_Png ,
        srcPng1080: Popcorn_1_768_Png,
        srcJpg: Popcorn_1_768_Jpg,
        srcJpg320: Popcorn_1_320_Jpg,
        srcJpg480: Popcorn_1_480_Jpg,
        srcJpg768: Popcorn_1_480_Jpg ,
        srcJpg1080: Popcorn_1_768_Jpg,
        link: 'https://www.behance.net/gallery/96587339/PopCorn',
    },
    {
        imageId: 'encuentro-2',
        altImg: 'Fotografía titulada «En remolinos»',
        description: `<span class="bold">«En remolinos</span> es una obra expuesta en el rubro Visuales de la 11va. Bienal de Arte Joven.`,
        srcAvif: Remolinos_768_Avif,
        srcAvif320: Remolinos_320_Avif,
        srcAvif480: Remolinos_480_Avif,
        srcAvif768: Remolinos_480_Avif ,
        srcAvif1080: Remolinos_768_Avif,
        srcWebp: Remolinos_768_Webp,
        srcWebp320: Remolinos_320_Webp,
        srcWebp480: Remolinos_480_Webp,
        srcWebp768: Remolinos_480_Webp ,
        srcWebp1080: Remolinos_768_Webp,
        srcPng: Remolinos_768_Png,
        srcPng320: Remolinos_320_Png,
        srcPng480: Remolinos_480_Png,
        srcPng768: Remolinos_480_Png ,
        srcPng1080: Remolinos_768_Png,
        srcJpg: Remolinos_768_Jpg,
        srcJpg320: Remolinos_320_Jpg,
        srcJpg480: Remolinos_480_Jpg,
        srcJpg768: Remolinos_480_Jpg ,
        srcJpg1080: Remolinos_768_Jpg,
        link: 'https://www.instagram.com/chechu.mcdp/',
    },
    {
        imageId: 'ojos',
        altImg: 'Fotografía titulada «Los ojos ciegos bien abiertos»',
        description: `<span class="bold">«Los ojos ciegos bien abiertos»</span> es una obra expuesta en el rubro Visuales de la 9na. Bienal de Arte Joven.`,
        srcAvif: Ojos_1_768_Avif,
        srcAvif320: Ojos_1_320_Avif,
        srcAvif480: Ojos_1_480_Avif,
        srcAvif768: Ojos_1_480_Avif ,
        srcAvif1080: Ojos_1_768_Avif,
        srcWebp: Ojos_1_768_Webp,
        srcWebp320: Ojos_1_320_Webp,
        srcWebp480: Ojos_1_480_Webp,
        srcWebp768: Ojos_1_480_Webp ,
        srcWebp1080: Ojos_1_768_Webp,
        srcPng: Ojos_1_768_Png,
        srcPng320: Ojos_1_320_Png,
        srcPng480: Ojos_1_480_Png,
        srcPng768: Ojos_1_480_Png ,
        srcPng1080: Ojos_1_768_Png,
        srcJpg: Ojos_1_768_Jpg,
        srcJpg320: Ojos_1_320_Jpg,
        srcJpg480: Ojos_1_480_Jpg,
        srcJpg768: Ojos_1_480_Jpg ,
        srcJpg1080: Ojos_1_768_Jpg,
        link: 'https://www.instagram.com/chechu.mcdp/',
    },
    {
        imageId: 'fito-1',
        altImg: 'Fotografía de Fito Páez',
        description: `Fotografía de recitales para <span class="bold">«Producciones del Plaza»</span>.`,
        srcAvif: Rock_1_768_Avif,
        srcAvif320: Rock_1_320_Avif,
        srcAvif480: Rock_1_480_Avif,
        srcAvif768: Rock_1_480_Avif ,
        srcAvif1080: Rock_1_768_Avif,
        srcWebp: Rock_1_768_Webp,
        srcWebp320: Rock_1_320_Webp,
        srcWebp480: Rock_1_480_Webp,
        srcWebp768: Rock_1_480_Webp ,
        srcWebp1080: Rock_1_768_Webp,
        srcPng: Rock_1_768_Png,
        srcPng320: Rock_1_320_Png,
        srcPng480: Rock_1_480_Png,
        srcPng768: Rock_1_480_Png ,
        srcPng1080: Rock_1_768_Png,
        srcJpg: Rock_1_768_Jpg,
        srcJpg320: Rock_1_320_Jpg,
        srcJpg480: Rock_1_480_Jpg,
        srcJpg768: Rock_1_480_Jpg ,
        srcJpg1080: Rock_1_768_Jpg,
        link: 'https://www.flickr.com/photos/visionpropia',
    },
    {
        imageId: 'lola-1',
        altImg: 'Fotografía general',
        description: `Fotografía general`,
        srcAvif: Lola_1_768_Avif,
        srcAvif320: Lola_1_320_Avif,
        srcAvif480: Lola_1_480_Avif,
        srcAvif768: Lola_1_480_Avif ,
        srcAvif1080: Lola_1_768_Avif,
        srcWebp: Lola_1_768_Webp,
        srcWebp320: Lola_1_320_Webp,
        srcWebp480: Lola_1_480_Webp,
        srcWebp768: Lola_1_480_Webp ,
        srcWebp1080: Lola_1_768_Webp,
        srcPng: Lola_1_768_Png,
        srcPng320: Lola_1_320_Png,
        srcPng480: Lola_1_480_Png,
        srcPng768: Lola_1_480_Png ,
        srcPng1080: Lola_1_768_Png,
        srcJpg: Lola_1_768_Jpg,
        srcJpg320: Lola_1_320_Jpg,
        srcJpg480: Lola_1_480_Jpg,
        srcJpg768: Lola_1_480_Jpg ,
        srcJpg1080: Lola_1_768_Jpg,
        link: 'https://www.instagram.com/chechu.mcdp/',
    },
    {
        imageId: 'pinhole-2',
        altImg: 'Fotografía estenopeica sacada para los talleres de «Alalata Fotografía»',
        description: `Fotografía estenopeica sacada para los talleres de <span class="bold">«Alalata Fotografía»</span>`,
        srcAvif: Pinhole_1_768_Avif,
        srcAvif320: Pinhole_1_320_Avif,
        srcAvif480: Pinhole_1_480_Avif,
        srcAvif768: Pinhole_1_480_Avif ,
        srcAvif1080: Pinhole_1_768_Avif,
        srcWebp: Pinhole_1_768_Webp,
        srcWebp320: Pinhole_1_320_Webp,
        srcWebp480: Pinhole_1_480_Webp,
        srcWebp768: Pinhole_1_480_Webp ,
        srcWebp1080: Pinhole_1_768_Webp,
        srcPng: Pinhole_1_768_Png,
        srcPng320: Pinhole_1_320_Png,
        srcPng480: Pinhole_1_480_Png,
        srcPng768: Pinhole_1_480_Png ,
        srcPng1080: Pinhole_1_768_Png,
        srcJpg: Pinhole_1_768_Jpg,
        srcJpg320: Pinhole_1_320_Jpg,
        srcJpg480: Pinhole_1_480_Jpg,
        srcJpg768: Pinhole_1_480_Jpg ,
        srcJpg1080: Pinhole_1_768_Jpg,
        link: 'https://www.behance.net/gallery/96872605/Alalata-Fotografia-Estenopeica',
    },
    {
        imageId: 'nona-1',
        altImg: 'Fotografía para muestra titulada «El encuentro», organizada por grupo «Once Mujeres»',
        description: `Fotografía para muestra titulada <span class="bold">«El encuentro»</span>, organizada por grupo «Once Mujeres».`,
        srcAvif: Encuentro_1_768_Avif,
        srcAvif320: Encuentro_1_320_Avif,
        srcAvif480: Encuentro_1_480_Avif,
        srcAvif768: Encuentro_1_480_Avif ,
        srcAvif1080: Encuentro_1_768_Avif,
        srcWebp: Encuentro_1_768_Webp,
        srcWebp320: Encuentro_1_320_Webp,
        srcWebp480: Encuentro_1_480_Webp,
        srcWebp768: Encuentro_1_480_Webp ,
        srcWebp1080: Encuentro_1_768_Webp,
        srcPng: Encuentro_1_768_Png,
        srcPng320: Encuentro_1_320_Png,
        srcPng480: Encuentro_1_480_Png,
        srcPng768: Encuentro_1_480_Png ,
        srcPng1080: Encuentro_1_768_Png,
        srcJpg: Encuentro_1_768_Jpg,
        srcJpg320: Encuentro_1_320_Jpg,
        srcJpg480: Encuentro_1_480_Jpg,
        srcJpg768: Encuentro_1_480_Jpg ,
        srcJpg1080: Encuentro_1_768_Jpg,
        link: 'https://www.instagram.com/chechu.mcdp/',
    },
    {
        imageId: 'sociales-1',
        altImg: 'Fotografía de eventos sociales',
        description: `Fotografía de eventos sociales`,
        srcAvif: Sociales_1_768_Avif,
        srcAvif320: Sociales_1_320_Avif,
        srcAvif480: Sociales_1_480_Avif,
        srcAvif768: Sociales_1_480_Avif ,
        srcAvif1080: Sociales_1_768_Avif,
        srcWebp: Sociales_1_768_Webp,
        srcWebp320: Sociales_1_320_Webp,
        srcWebp480: Sociales_1_480_Webp,
        srcWebp768: Sociales_1_480_Webp ,
        srcWebp1080: Sociales_1_768_Webp,
        srcPng: Sociales_1_768_Png,
        srcPng320: Sociales_1_320_Png,
        srcPng480: Sociales_1_480_Png,
        srcPng768: Sociales_1_480_Png ,
        srcPng1080: Sociales_1_768_Png,
        srcJpg: Sociales_1_768_Jpg,
        srcJpg320: Sociales_1_320_Jpg,
        srcJpg480: Sociales_1_480_Jpg,
        srcJpg768: Sociales_1_480_Jpg ,
        srcJpg1080: Sociales_1_768_Jpg,
        link: 'https://www.instagram.com/chechu.mcdp/',
    },
    /* 
    fabricio, fotos eliana, fotos sandra ingles, foto creativa,
    */
]

export default photography;