<template>
    <header>
        <nav v-if="!isPortrait">
            <!-- <ButtonIcon icon="fa-solid fa-file-code" text="Versión desarrollo" href="#"/> --> <!-- ver link o route -->
            <!-- <ButtonIcon icon="fa-solid fa-palette" text="Gama crómatica" href="#"/> --><!-- ver link o route -->
            <ButtonIcon icon="fa-solid fa-envelope" text="depaulacecilia@gmail.com" href="mailto:depaulacecilia@gmail.com?subject=Contacto laboral" target="_blank"/>
            <ButtonIcon icon="fa-solid fa-phone" text="+56 9 75976975" href="https://api.whatsapp.com/send?phone=56975976975&text=¡Hola%20Cecilia!%20He%20visto%20tu%20web%20y%20estoy%20interesado%20en%20contactarme%20contigo." target="_blank" />
        </nav>
    </header>
</template>

<script>
import ButtonIcon from '@/components/ButtonIcon.vue';

export default {
    name: 'DesignNavBar',
    components: {
        ButtonIcon,
    },
    data(){
        return {
            isPortrait: window.innerHeight > window.innerWidth,
        }
    },
    mounted() {
        window.addEventListener('resize', this.updateScreenSize);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.updateScreenSize);
    },
    methods: {        
        updateScreenSize() {
            this.isPortrait = window.innerHeight > window.innerWidth;
        },
    },
}
</script>

<style scoped>
header{
    position: absolute;
    top: 0;
    width: 100vw;
    padding: 9vw 5.6vw;
    z-index: 2;
}
nav{
    display: flex;
    justify-content: flex-end;
    gap: 1vw;
}
/* RESPONSIVE */
@media (min-width:768px) {
    header{
        padding: 1.8vw 5.6vw;
    }
}
</style>