<template>
    <div class="cv-container">
        <div class="information">
            <div>
                <p class="introduction-subtitle">¿Por qué diseño y desarrollo?</p>
                <p class="text">
                    Al terminar mis estudios en <span class="bolder">diseño</span> decidí seguir capacitándome en <span class="bolder">UX/UI</span>, rama que despertó en mí un interés por la programación. Gracias a los conocimientos obtenidos en diseño web ya podía proyectar el área más superficial y visible de un sitio, pero eso no era suficiente, quería ir más allá, quería conocer todo lo que pasaba por detrás. Por ese motivo decidí estudiar <span class="bolder">desarrollo web</span>, para entender cómo eso que diseñaba se materializaba con el código. <!-- De este modo, el diseño UX/UI se convirtió en el nexo entre el desarrollo y la comunicación visual. -->
                </p>
            </div>
            <ButtonDownload text="Descargar curriculum" href="https://drive.google.com/file/d/1R7vUWEkPT1h-f_rsKzo8wqwr5Ao6h8Dc/view"/> <!-- CAMBIAR LINK -->
        </div>
        <div class="decoration animate__animated animate__slideInRight animate__fadeIn">
            <div class="decoration-content">
                <PictureStructure 
                :srcAvif="StatueAvif"
                :srcAvif320="StatueAvif320"
                :srcAvif480="StatueAvif480"
                :srcAvif768="StatueAvif768"
                :srcAvif1080="StatueAvif1080"
                :srcWebp="StatueWebp"
                :srcWebp320="StatueWebp320"
                :srcWebp480="StatueWebp480"
                :srcWebp768="StatueWebp768"
                :srcWebp1080="StatueWebp1080"
                :srcPng="StatuePng"
                :srcPng320="StatuePng320"
                :srcPng480="StatuePng480"
                :srcPng768="StatuePng768"
                :srcPng1080="StatuePng1080"
                altImg="Estatua de estilo renacentista de una mujer usando una notebook"
                classes="cv-statue"
                />
                <div class="gradient"></div>
                <div class="yellow-circle animate__animated animate__fadeIn animate__slideInRight"></div>
            </div>
        </div>
    </div>
</template>

<script>
import ButtonDownload from '@/components/ButtonDownload.vue';
import PictureStructure from '@/components/PictureStructure.vue';
import StatueAvif from '@/assets/img/statues/avif/curriculum_statue.avif';
import StatueAvif320 from '@/assets/img/statues/avif/curriculum_statue_320.avif';
import StatueAvif480 from '@/assets/img/statues/avif/curriculum_statue_480.avif';
import StatueAvif768 from '@/assets/img/statues/avif/curriculum_statue_768.avif';
import StatueAvif1080 from '@/assets/img/statues/avif/curriculum_statue_1080.avif';
import StatuePng from '@/assets/img/statues/png/curriculum_statue.png';
import StatuePng320 from '@/assets/img/statues/png/curriculum_statue_320.png';
import StatuePng480 from '@/assets/img/statues/png/curriculum_statue_480.png';
import StatuePng768 from '@/assets/img/statues/png/curriculum_statue_768.png';
import StatuePng1080 from '@/assets/img/statues/png/curriculum_statue_1080.png';
import StatueWebp from '@/assets/img/statues/webp/curriculum_statue.webp';
import StatueWebp320 from '@/assets/img/statues/webp/curriculum_statue_320.webp';
import StatueWebp480 from '@/assets/img/statues/webp/curriculum_statue_480.webp';
import StatueWebp768 from '@/assets/img/statues/webp/curriculum_statue_768.webp';
import StatueWebp1080 from '@/assets/img/statues/webp/curriculum_statue_1080.webp';
import 'animate.css';

export default {
    name: 'CVIntroduction',
    components: {
        ButtonDownload,
        PictureStructure,
    },
    props:  {
    },
    data() {
        return {
            StatueAvif, StatueAvif320, StatueAvif480, StatueAvif768, StatueAvif1080,
            StatuePng, StatuePng320, StatuePng480, StatuePng768, StatuePng1080,
            StatueWebp, StatueWebp320, StatueWebp480, StatueWebp768, StatueWebp1080,
        }
    },
}
</script>

<style scoped>
.information {
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.cv-container{
    display: flex;
}
.decoration{
    min-height: calc(63.207vh + 3.3vh);
    max-height: calc(95vh - 17.85vh - 6px);
    position: relative;
    z-index: -2;
    display: flex;
    justify-content: flex-end;
}
.decoration-content{
    width: fit-content;
}
.text{
    margin-bottom: 4.4vh;
}
.yellow-circle{
    background-color: var(--yellow);
    width: 60vh;
    height: 60vh;
    border-radius: 60vh;
    position: relative;
    z-index: -2;
}
.gradient{
    background: var(--background-gradient);
    height: 20vh;
    position: relative;
    z-index: 2;
}
@media only screen and (orientation : portrait){
    .cv-container{
        flex-direction: column;
    }
    .yellow-circle{
        bottom: 45vh;
        left: 10vh;
    }
    .gradient{
        width: 100%;
        bottom: -1vh;
        margin-bottom: -20vh;
    }
    .decoration{
        margin-bottom: 20vh;
        justify-content: center;
    }
}
@media only screen and (orientation : landscape){
    .yellow-circle{
        bottom: 65vh;
        left: 10vh;
    }
    .gradient{
        width: 100%;
        bottom: 19vh;
        margin-bottom: -20vh;
    }
    .decoration{
        padding: 0 0 0 1vh;
    }
    .information{
        padding-right: 4vw;
    }
}
@media only screen and (orientation : landscape) and (max-aspect-ratio: 1.7/1){
    .cv-container{
        flex-direction: column;
    }
    .information {
        margin-bottom: 10vh;
    }
    .decoration{
        justify-content: center;
    }
}
@media only screen and (orientation : landscape) and (min-aspect-ratio: 1.7/1){
    .cv-container{
        flex-direction: row;
        max-height: calc(95vh - 17.85vh - 6px);   
    }
    .information {
        height: calc(60.51275178vh - 1.2vh);
    }
}
</style>
<style>
.cv-statue{
    transform: scaleX(-1);
    position: relative;
    z-index: -1;
    min-height: calc(68.207vh + 3.3vh);
    max-height: calc(95vh - 12.85vh - 6px); 
}
@media only screen and (orientation : portrait){
    .cv-statue{
        top: 10vh;
        margin-bottom: -10vh;
    }
}
@media only screen and (orientation : landscape){
    .cv-statue{
        top: -14.6vh;
        margin-bottom: -14.6vh;
    }
}

</style>