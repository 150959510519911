<template>
    <div class="accordion-item" :class="{ 'secondary-acordion': secondatyAcordion}" >
        <button type="button collapsed" data-bs-toggle="collapse" :data-bs-target="`#collapse${sectionId}`"
        aria-expanded="false" :aria-controls="`#collapse${sectionId}`" class="w-100" @click="clickAction(id)">
            <div class="d-flex align-items-end justify-content-between">
                <h2 v-if="!secondatyAcordion"><span class="landscape-name">{{ sectionName }}</span> <span class="portrait-name">{{ spanName }}</span></h2>
                <div v-else class="container-title-item">
                    <p class="subtitle-accordion section-name">{{ sectionName }}</p>
                    <p class="subtitle-accordion span-year"><span class="slash">/</span>{{ spanName }}</p>
                </div>
                <i class="ri-arrow-right-down-line"></i>
                <i class="ri-close-line d-none"></i>
            </div>
        </button>
        <div :id="`collapse${sectionId}`" class="accordion-collapse collapse " data-bs-parent="#accordionSections"> <!-- sacar show -->
            <div class="accordion-body">
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AccordionItem',
    components: {
    },
    props: {
        id: {
            type: Number,
        },
        sectionId: {
            type: String,
        },
        sectionName: {
            type: String,
        },
        spanName: {
            type: String,
        },
        secondatyAcordion:{
            type: Boolean,
            default: false,
        },
        clickedFunction: Function,
    },
    methods: {
        clickAction(i) {
            if (this.clickedFunction) {
                this.clickedFunction();
            }
            let open = document.getElementsByClassName('ri-arrow-right-down-line')[i];
            let close = document.getElementsByClassName('ri-close-line')[i];

            open.classList.toggle("d-none");
            close.classList.toggle("d-none");
        },
    },
    data() {
        return {
        }
    },
}
</script>

<style scoped>
div {
    color: var(--white);
}
button{
    background-color: var(--back);
    border: 0;
    position: relative;
    z-index: 10;
}
i.ri-arrow-right-down-line, i.ri-close-line{
    color: var(--white);
    font-size: 11.5vh;
    opacity: 0.9;
    position: relative;
    top: 2.3vh;
    left: 2vh;
}
.accordion-item{
    border-top: var(--yellow) 3px solid;
}

div.accordion-item.secondary-acordion i.ri-arrow-right-down-line, 
div.accordion-item.secondary-acordion i.ri-close-line{
    font-size: 5.4vh;
    top: 0vh;
    left: 1vh;
}

div.accordion-item.secondary-acordion{
    border-top: var(--white) 2px solid;
}

div.accordion-item.secondary-acordion p{
    margin-top: 2vh;
    text-align: left;
}

.span-year{
    font-weight: 100;
}
.container-title-item{
    display: flex;
}
@media only screen and (orientation : portrait){
    .container-title-item{
        flex-direction: column;
    }
    .span-year{
        padding-left: 0vh;
    }
    .slash{
        display: none;
    }
    div.accordion-item.secondary-acordion p.span-year{
        margin-top: 0;
    }
    div.accordion-item.secondary-acordion p.section-name{
        margin-bottom: 1vh;
    }
    .landscape-name{
        display: none;
    }
    .portrait-name{
        display: initial;
    }
}
@media only screen and (orientation : landscape){
    .container-title-item{
        flex-direction: row;
    }
    .slash{
        padding: 0 1vh;
    }
    .landscape-name{
        display: initial;
    }
    .portrait-name{
        display: none;
    }
}
</style>
