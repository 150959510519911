<template>
    <div class="portfolio-container">
        <div class="information">
            <p class="text">
                A continuación se presentan algunos de mis trabajos favoritos divididos en cuatro secciones: diseño web, desarrollo web, diseño gráfico y fotográfia. Al hacer click sobre las imágenes podrás acceder a más información e incluso podrás ir hacia otros sitios en donde puedes verlos completos.
            </p>
            <div class="container-buttons">
                <div class="buttons">
                    <template v-for="(button, index) in buttons" :key="index">
                        <ButtonSection :invert="true" :isActive="button.clicked" :text="button.text" @click="clickAction(index)"/><!-- :href="button.href" -->
                    </template>
                </div>
                <ButtonDownload text="Descargar Portfolio" href="https://drive.google.com/file/d/11FlKf0B-ssmghPYey2WUVLY5OiwTl0mD/view"/> <!-- CAMBIAR LINK -->
            </div>
            <div class="shown-section">
                <PortfolioDevelopment v-if="buttons[0].clicked"/>
                <PortfolioWebDesign v-if="buttons[1].clicked"/>
                <PortfolioGraphicDesign v-if="buttons[2].clicked"/>
                <PortfolioPhotography v-if="buttons[3].clicked"/>
            </div>
        </div>
    </div>
</template>

<script>
import ButtonDownload from '@/components/ButtonDownload.vue';
import ButtonSection from '@/components/ButtonSection.vue';
import PortfolioGraphicDesign from './PortfolioGraphicDesign.vue'; 
import PortfolioDevelopment from './PortfolioDevelopment.vue'; 
import PortfolioWebDesign from './PortfolioWebDesign.vue';
import PortfolioPhotography from './PortfolioPhotography.vue';

export default {
    name: 'PortfolioInfo',
    components: {
        ButtonDownload, ButtonSection, PortfolioGraphicDesign, PortfolioDevelopment, PortfolioWebDesign, PortfolioPhotography,
    },
    data() {
        return {
            buttons: [
                { id: 0 , text: 'Desarrollo web', href: '#', clicked: true,},
                { id: 1, text: 'Diseño web', href: '#', clicked: false,},
                { id: 2, text: 'Diseño gráfico', href: '#', clicked: false,},
                { id: 3, text: 'Fotografía', href: '#', clicked: false,},
            ],
        };
    },
    methods: {
        clickAction(index) {
            for (let i = 0; i < this.buttons.length; i++) {
                this.buttons[i].clicked = false;
            }
            this.buttons[index].clicked = !this.buttons[index].clicked;
        },
    },
}
</script>

<style scoped>
.information {
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.portfolio-container{
    display: flex;
    margin-bottom: 4.4vh;
    /* border: red solid 1px; */
}
.text{
    margin-bottom: 4.4vh;
}
@media only screen and (orientation : portrait){
    .portfolio-container{
        flex-direction: column;
    }
    .text{
        width: 100%;
    }
    .buttons{
        display: flex;
        row-gap: 1.5vh;
        flex-direction: column;
    }
    .container-buttons{
        display: flex;
        flex-direction: column-reverse;
        max-width: 90vw;
        row-gap: 6vh;
    }
}
@media only screen and (orientation : landscape){
    .information{
        padding-right: 4vw;
    }
    .text{
        width: 65%;
    }
    .buttons{
        display: flex;
        column-gap: 1vw;
    }
    .container-buttons{
        display: flex;
        justify-content: space-between;
        max-width: 90vw;
    }
}
@media only screen and (max-width:768px) {
    .text{
        width: 90vw;
    }
}
</style>