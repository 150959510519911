<template>
    <picture :class="classesAnimation">
        <source v-if="srcSvg" :srcset="`${srcSvg}`" type="image/svg+xml"/>
        <source v-if="srcWebp" :srcset="`${srcWebp320} 480w, ${srcWebp480} 768w, ${srcWebp768} 1080w, ${srcWebp1080} 1200w, ${srcWebp} 1400w`" type="image/webp"/>
        <source v-if="srcAvif" :srcset="`${srcAvif320} 480w, ${srcAvif480} 768w, ${srcAvif768} 1080w, ${srcAvif1080} 1200w, ${srcAvif} 1400w`" type="image/avif"/> 
        <source v-if="srcJpg" :srcset="`${srcJpg320} 480w, ${srcJpg480} 768w, ${srcJpg768} 1080w, ${srcJpg1080} 1200w, ${srcJpg} 1400w`" type="image/jpeg"/>
        <source v-if="srcPng" :srcset="`${srcPng320} 480w, ${srcPng480} 768w, ${srcPng768} 1080w, ${srcPng1080} 1200w, ${srcPng} 1400w`" type="image/png"/>
        <img
            :srcset="`${srcWebp320} 480w, ${srcWebp480} 768w, ${srcWebp768} 1080w, ${srcWebp1080} 1200w, ${srcWebp} 1400w`"
            sizes="(max-width: 400px) 100%, (max-width: 480px) 100%, (max-width: 768px) 100%, (max-width: 1080px) 100%, 1200px"
            :src=srcWebp :alt=altImg loading="lazy" :class=classes />
    </picture>
</template>

<script>
export default {
    name: 'PictureStructure',
    props:  {
        altImg: { type: String, },
        classes: { type: String, },
        srcSvg: { type: String, },
        srcAvif: { type: String, },
        srcAvif320: { type: String, },
        srcAvif480: { type: String, },
        srcAvif768: { type: String, },
        srcAvif1080: { type: String, },
        srcWebp: { type: String, },
        srcWebp320: { type: String, },
        srcWebp480: { type: String, },
        srcWebp768: { type: String, },
        srcWebp1080: { type: String, },
        srcPng: { type: String, },
        srcPng320: { type: String, },
        srcPng480: { type: String, },
        srcPng768: { type: String, },
        srcPng1080: { type: String, },
        srcJpg: { type: String, },
        srcJpg320: { type: String, },
        srcJpg480: { type: String, },
        srcJpg768: { type: String, },
        srcJpg1080: { type: String, },
        classesAnimation: { type: String, },
    },
}
</script>

<style scoped>
img{
    width: auto;
    height: auto;
}
</style>