<template>
    <div id="container-web-design-section">
        <Swiper 
        :slidesPerView="'auto'"
        :centeredSlides="false"
        :spaceBetween="30"
        :modules="modules"
        :speed="1000"
        :pagination="{
            clickable: true,
        }"
        :grabCursor="true"
        class="mySwiper m-0">
            <template v-for="(image, index) in WebDesign" :key="index">
                <SwiperSlide>
                    <div :id="image.imageId" class="container-img">
                        <PictureStructure 
                        :altImg="image.altImg"
                        :srcAvif="image.srcAvif"
                        :srcAvif320="image.srcAvif320"
                        :srcAvif480="image.srcAvif480"
                        :srcAvif768="image.srcAvif768"
                        :srcAvif1080="image.srcAvif1080"
                        :srcWebp="image.srcWebp"
                        :srcWebp320="image.srcWebp320"
                        :srcWebp480="image.srcWebp480"
                        :srcWebp768="image.srcWebp768"
                        :srcWebp1080="image.srcWebp1080"
                        :srcPng="image.srcPng"
                        :srcPng320="image.srcPng320"
                        :srcPng480="image.srcPng480"
                        :srcPng768="image.srcPng768"
                        :srcPng1080="image.srcPng1080"
                        :srcJpg="image.srcJpg"
                        :srcJpg320="image.srcJpg320"
                        :srcJpg480="image.srcJpg480"
                        :srcJpg768="image.srcJpg768"
                        :srcJpg1080="image.srcJpg1080"
                        classes="img-height" />
                        <div class="text-information">
                            <p v-html="formatDescription(image.description)" class="description-img"></p>
                            <a class="no-decoration" :href="image.link" target="blank_"> <!-- VER LINKS o route -->
                                <div class="link">
                                    <a class="more">{{ image.txtLink }}</a>
                                    <i class="ri-arrow-right-up-line"></i>
                                </div>
                            </a>
                        </div>
                    </div>
                </SwiperSlide> 
            </template>
        </Swiper>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import { FreeMode, Autoplay, Pagination } from 'swiper/modules';
import PictureStructure from '@/components/PictureStructure.vue';
import DOMPurify from 'dompurify';
import WebDesign from './web-design.js'

export default {
    name: 'PortfolioWebDesign',
    components: {
        Swiper, SwiperSlide, PictureStructure, 
    },
    setup() {
        return {
            modules: [FreeMode, Autoplay, Pagination],
        };
    },
    data() {
        return {
            WebDesign,
        }
    },
    methods: {
        formatDescription(description) {
            const cleanHTML = DOMPurify.sanitize(description);
            return cleanHTML;
        },
    },
}
</script>

<style scoped>
#container-web-design-section{
    margin-top: 3vh;
    width: 95vw;
}
.container-img{
    width: min-content;
    border: var(--white) 1px solid;
}
.description-img{
    margin: 1vh 0 0 0;
}
.link{
    border-left: var(--white) 1px solid;
}
.text-information{
    display: flex;
    align-items: center;
    border-top: var(--white) 1px solid;
    justify-content: space-between;
    position: relative;
    z-index: 2;
}
.text-information p{
    margin: 0;
    font-size: 2.4vh;
}
.link{
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
}
.link a.more{
    transition: all 0.5s ease-in-out;
    text-decoration: none;
    color: var(--black);
    background-color: var(--white);
    position: absolute;
    left: 0;
    font-family: var(--font-monospace);
    text-transform: uppercase;
    width: 0px;
    overflow: hidden;
    display: flex;
    align-items: center;
    white-space: nowrap;
}
.no-decoration{
    text-decoration: none;
    color: var(--white);
}
.link .ri-arrow-right-up-line{
    font-size: 3.2vh;
    padding: 0 2vh;
    transition: all 0.5s ease-in-out;
    position: relative;
    z-index: 1;
}
.link:hover{
    background-color: var(--white);
    border-left: var(--black) 1px solid;
}
.link:hover .ri-arrow-right-up-line{
    color: var(--black);
    padding: 0 3vh;
}
.link:hover a{
    width: 100%;
    padding-left: 2vh;
}
@media only screen and (orientation: portrait){
    .text-information p{
        padding: 2vw;
        font-size: 2.1vh;
    }
    .text-information, .link, .link a.more{
        height: 12vh;
    }
}
@media only screen and (orientation: landscape){
    #container-web-design-section{
        height: 80vh;
    }
    .container-img{
        height: 80vh;
    }
    .text-information p{
        font-size: 2.4vh;
        padding: 0 1vw;
    }
    .text-information, .link, .link a.more{
        height: 9vh;
    }
}
</style>


<style lang="scss">
#container-web-design-section {
    .swiper{
        overflow: visible;
        .swiper-slide{
            max-width: fit-content;
        }
        .swiper-slide:last-child{
            margin-right: 5vw;
        }
        .swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal {
            max-width: fit-content;
            .swiper-pagination-bullet {
                border-radius: 0;
                position: relative;
                bottom: -1vw;
            }
            .swiper-pagination-bullet-active{
                background-color: var(--yellow);
            }
            :not(.swiper-pagination-bullet-active){
                background-color: var(--white);
                opacity: 0.6;
            }
        }
    }
}
@media only screen and (orientation: portrait){
    #container-web-design-section {
        .swiper{
            .swiper-slide{
                .img-height{
                    height: 90vw;
                }
            }
            .swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal {
                min-width: 90vw;
                position: relative;
                top: 1vh;
            }
        }
    }
}
@media only screen and (orientation : landscape){
    #container-web-design-section {
        .swiper{
            .swiper-slide{
                .img-height{
                    height: calc(100% - 9vh);
                }
            }
            .swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal {
                max-width: fit-content;
                transform: rotate(90deg);
                background-color: var(--black);
                position: absolute;
                left: -25vw;
                bottom: 17vw;
                min-width: 43vw;
                height: 6vw;
            }
        }
    }
}
</style>