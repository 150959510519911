<template>
    <header>

    </header>
    <main>
        <section>
            Developer
        </section>
    </main>
    <footer>
        
    </footer>
  </template>
  
  <script>
  
  export default {
    name: 'DesignPage',
    components: {
  },
  }
  </script>
  
  <style scoped>
  </style>
  