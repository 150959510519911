let specialities = [
    {
        id: '0',
        circleTitle: 'Diseño gráfico',
        circleSubTitle: 'LDCV',
        title: 'Lic. en Diseño de la Comunicación Visual',
        text: 'A finales de 2019 egresé de FADU UNL en la carrera de Lic. en Diseño de la Comunicación Visual, obteniendo a su vez la mención de Cuadro de Honor por ser uno de los mejores promedios de la Provincia de Santa Fe en la promoción 2019.',
        size: 'big-circle',
        clicked: false,
    },
    {
        id: '1',
        circleTitle: 'Desarrollo web',
        circleSubTitle: 'DWFS',
        title: 'Desarrollo Web Full Stack',
        text: 'Con mis conocimientos en diseño yo ya podía proyectar el área más superficial y visible de un sitio web, pero eso no era suficiente: quería ir más allá, quería conocer todo lo que pasaba por detrás. Por ese motivo decidí estudiar Desarrollo Web Full Stack en Acámica, para entender cómo eso que proyectaba con el diseño se podía materializar con el código.',
        size: 'big-circle',
        clicked: false,
    },
    {
        id: '2',
        circleTitle: 'Fotografía',
        title: 'Fotografía',
        text: 'Comencé estudiando fotografía en el Estudio Guidotti y más tarde en la Escuela Alem. Posteriormente, esto me permitió desempeñarme como fotógrafa de recitales y eventos varios, así como para también participar de diferentes muestras y concursos. En la actualidad dirijo Alalata Fotografía, un taller experimental de fotografía estenopeica en donde se le enseña al alumno a crear su propia cámara fotográfica, a sacar fotos con ella y luego a revelarlas en el cuarto oscuro.',
        size: 'medium-circle',
        clicked: false,
    },
    {
        id: '3',
        circleTitle: 'UX/UI',
        title: 'Diseño UX/UI',
        text: 'Al terminar mis estudios de grado decidí seguir capacitándome en UX/UI. Por este motivo realicé un curso en Coder House, el cual sirvió para potenciar mis conocimientos en diseño web, tanto en el diseño de interfaces como en la experiencia de usuario. Llevar adelante dicho curso despertó en mí un interés por la programación y así el diseño UX/UI se convirtió en el nexo entre el desarrollo y la comunicación visual.',
        size: 'medium-circle',
        clicked: false,
    },
    {
        id: '4',
        circleTitle: 'Tipografía',
        title: 'Tipografía',
        text: 'Mi relación con la tipografía empezó a hacerse más estrecha cuando llevé a cabo la pasantía en docencia en los niveles de Tipografía I y II de la Cátedra González en FADU UNL. Tal es así que al año siguiente fui convocada para formar parte del equipo de Tipos Latinos Sede Santa Fe. Años más tarde ambos hechos repercutieron a la hora de llevar a cabo la Beca de Iniciación a la Investigación Científica y la Tesina de grado, ambas enfocadas en el estudio de la tipografía latinoamericana.',
        size: 'medium-circle',
        clicked: false,
    },
    {
        id: '5',
        circleTitle: 'Semiótica',
        title: 'Semiótica',
        text: 'Una de las disciplinas en las que más hice hincapié al estudiar Diseño y, que a su vez, fue la que posibilitó hacer el abordaje teórico de la Tesina de Grado, fue la semiótica. El análisis semiótico de los signos es el que aún hoy repercute a la hora de analizar o proyectar cualquier pieza de diseño, ya que permite analizar el proceso de significación de la misma.',
        size: 'medium-circle',
        clicked: false,
    },  
    {
        id: '6',
        circleTitle: 'Docencia',
        title: 'Docencia',
        text: 'Durante mi período de estudiante de diseño, tras finalizar de cursar los dos niveles de Tipografía obligatorios (Tipografía I y Tipografía II de la Cátedra González), tuve la posibilidad de llevar a cabo mi primera pasantía en docencia. Esta experiencia luego me sirvió para llevar a cabo un proyecto personal de enseñanza de fotografía estenopeica experimental, en Alalata Fotografía.',
        size: 'small-circle',
        clicked: false,
    },
    {
        id: '7',
        circleTitle: 'Investigación',
        title: 'Investigación',
        text: 'En 2016 obtuve una Beca de Iniciación a la Investigación Científica en la UNL con la cual realicé mi primer trabajo de investigación. El mismo, posteriormente tuvo continuación con la Tesina de Grado titulada «Estudio de los procesos de hibridación y códigos visuales presentes en las tipografías experimentales latinoamericanas, a través de la relación formal/funcional del signo tipográfico», la cual fue invitada a formar parte del Proyecto CAI+D 2016 «Imágenes del Diseño: Cartografía de los modos de visibilizar. Argentina, desde mediados del SXX».',
        size: 'small-circle',
        clicked: false,
    },
    {
        id: '8',
        circleTitle: 'Tecnicatura',
        circleSubTitle: 'TCN',
        title: 'Tecnicatura',
        text: 'Cursé la secundaria en una escuela técnica: la Escuela Industrial Superior. Allí me recibí de Técnica Constructora Nacional (TCN). Si bien nunca ejercí, considero que haber terminado en una escuela técnica marcó significativamente mi forma de ser, de pensar y de trabajar. Por eso cuando empecé a estudiar Desarrollo, creo que el haber terminado en el Industrial me ayudó a la hora de abordar los problemas y las metodologías de trabajo.',
        size: 'small-circle',
        clicked: false,
    }
]

export default specialities;