<template>
    <div v-if="sectionId" @click="scrollTo(sectionId)">
        <div class="button" :class="{ 'invert': invert , 'active': isActive}">
            <p :class="classes">
                {{text}}
            </p>
        </div>
    </div>
    <a v-else :href=href>
        <div class="button" :class="{ 'invert': invert , 'active': isActive}">
            <p :class="classes">
                {{text}}
            </p>
        </div>
    </a>
</template>

<script>
export default {
    name: 'ButtonSection',
    props: {
        text: {
            type: String,
        },
        href: {
            type: String,
        },
        sectionId: {
            type: String,
        },
        classes: {
            type: String,
        },
        invert: {   
            type: Boolean,
            default: false,
        },
        isActive: {   
            type: Boolean,
            default: false,
        }
    },
    methods: {
        scrollTo(sectionId) {
            const scrollSection = document.getElementById(sectionId);
            scrollSection.scrollIntoView({ behavior: 'smooth' });
        }
    }

}
</script>

<style scoped>
a{
    text-decoration: none;
}
.button{
    background-color: var(--black);
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}
p{
    font-family: var(--font-monospace);
    text-align: center;
    color: var(--white);
    text-transform: uppercase;
    opacity: 0.9;
    transition: all 0.3s ease-in-out;
}
.button:hover{
    background-color: var(--white);
}
.button:hover p{
    color: var(--black);
}
.button.invert{
    background-color: var(--black);
    border: 1px solid var(--white);
}
.button.invert p{
    color: var(--white);
}
.button.invert:hover, .button.invert.active{
    background-color: var(--white);
}
.button.invert:hover p, .button.invert.active p{
    color: var(--black);
}
@media only screen and (orientation : portrait){
    p{
        font-size: 4.4vw;
        padding: 1.2vw 4vw;
        margin: 0;
    }
    p.presentation{
        padding: 1.7vw 7vw 1.2vw 4vw;
    }
}
@media only screen and (orientation : landscape){
    p, p.presentation{
        font-size: 1.5vw;
        padding: 0.2vw 1.6vw;
        margin: 0;
    }
}
</style>