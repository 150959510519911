<template>
    <section id="about-me">
        <AccordionItem 
        id="0"
        sectionId="AboutMe"
        sectionName="Sobre mí"
        spanName="Sobre mí"
        :clickedFunction="animationItems" 
        >
            <div v-if="isPortrait" class="container container-portrait">
                <PictureStructure 
                :srcAvif="StatueAvifHalf"
                :srcAvif320="StatueAvif320Half"
                :srcAvif480="StatueAvif480Half"
                :srcAvif768="StatueAvif768Half"
                :srcAvif1080="StatueAvif1080Half"
                :srcWebp="StatueWebpHalf"
                :srcWebp320="StatueWebp320Half"
                :srcWebp480="StatueWebp480Half"
                :srcWebp768="StatueWebp768Half"
                :srcWebp1080="StatueWebp1080Half"
                :srcPng="StatuePngHalf"
                :srcPng320="StatuePng320Half"
                :srcPng480="StatuePng480Half"
                :srcPng768="StatuePng768Half"
                :srcPng1080="StatuePng1080Half"
                altImg="Estatua de estilo renacentista de una mujer usando un teclado, con la mirada al frente"
                classes="about-me-statue"
                id="about-me-statue"
                class="animate__animated"
                />
            </div>
            <div v-else class="container container-landscape">
                <PictureStructure 
                :srcAvif="StatueAvif"
                :srcAvif320="StatueAvif320"
                :srcAvif480="StatueAvif480"
                :srcAvif768="StatueAvif768"
                :srcAvif1080="StatueAvif1080"
                :srcWebp="StatueWebp"
                :srcWebp320="StatueWebp320"
                :srcWebp480="StatueWebp480"
                :srcWebp768="StatueWebp768"
                :srcWebp1080="StatueWebp1080"
                :srcPng="StatuePng"
                :srcPng320="StatuePng320"
                :srcPng480="StatuePng480"
                :srcPng768="StatuePng768"
                :srcPng1080="StatuePng1080"
                altImg="Estatua de estilo renacentista de una mujer usando un teclado, con la mirada al frente"
                classes="about-me-statue"
                id="about-me-statue"
                class="animate__animated"
                />
            </div>
            <div class="container-text">
                <p class="just">Simplemente, Cecilia.</p>
                <p v-if="!isPortrait" class="nice">¡Un gusto conocerte!</p>
            </div>
            <div class="swiper-container">
                <SwiperRow class="swiper-row swiper-back" right="true" />
                <SwiperRow class="swiper-row swiper-front" />   
            </div>
        </AccordionItem>
    </section>
</template>

<script>
import AccordionItem from '@/components/AccordionItem.vue';
import PictureStructure from '@/components/PictureStructure.vue';
import SwiperRow from '@/components/SwiperRow.vue';
import StatueAvif from '@/assets/img/statues/avif/about_me_statue.avif';
import StatueAvif320 from '@/assets/img/statues/avif/about_me_statue_320.avif';
import StatueAvif480 from '@/assets/img/statues/avif/about_me_statue_480.avif';
import StatueAvif768 from '@/assets/img/statues/avif/about_me_statue_768.avif';
import StatueAvif1080 from '@/assets/img/statues/avif/about_me_statue_1080.avif';
import StatuePng from '@/assets/img/statues/png/about_me_statue.png';
import StatuePng320 from '@/assets/img/statues/png/about_me_statue_320.png';
import StatuePng480 from '@/assets/img/statues/png/about_me_statue_480.png';
import StatuePng768 from '@/assets/img/statues/png/about_me_statue_768.png';
import StatuePng1080 from '@/assets/img/statues/png/about_me_statue_1080.png';
import StatueWebp from '@/assets/img/statues/webp/about_me_statue.webp';
import StatueWebp320 from '@/assets/img/statues/webp/about_me_statue_320.webp';
import StatueWebp480 from '@/assets/img/statues/webp/about_me_statue_480.webp';
import StatueWebp768 from '@/assets/img/statues/webp/about_me_statue_768.webp';
import StatueWebp1080 from '@/assets/img/statues/webp/about_me_statue_1080.webp';
import StatueAvifHalf from '@/assets/img/statues/avif/about_me_statue_half.avif';
import StatueAvif320Half from '@/assets/img/statues/avif/about_me_statue_half_320.avif';
import StatueAvif480Half from '@/assets/img/statues/avif/about_me_statue_half_480.avif';
import StatueAvif768Half from '@/assets/img/statues/avif/about_me_statue_half_768.avif';
import StatueAvif1080Half from '@/assets/img/statues/avif/about_me_statue_half_1080.avif';
import StatuePngHalf from '@/assets/img/statues/png/about_me_statue_half.png';
import StatuePng320Half from '@/assets/img/statues/png/about_me_statue_half_320.png';
import StatuePng480Half from '@/assets/img/statues/png/about_me_statue_half_480.png';
import StatuePng768Half from '@/assets/img/statues/png/about_me_statue_half_768.png';
import StatuePng1080Half from '@/assets/img/statues/png/about_me_statue_half_1080.png';
import StatueWebpHalf from '@/assets/img/statues/webp/about_me_statue_half.webp';
import StatueWebp320Half from '@/assets/img/statues/webp/about_me_statue_half_320.webp';
import StatueWebp480Half from '@/assets/img/statues/webp/about_me_statue_half_480.webp';
import StatueWebp768Half from '@/assets/img/statues/webp/about_me_statue_half_768.webp';
import StatueWebp1080Half from '@/assets/img/statues/webp/about_me_statue_half_1080.webp';
import 'animate.css';

export default {
    name: 'DesignAboutMe',
    components: {
        AccordionItem, PictureStructure, SwiperRow,
    },
    data() {
        return {
            StatueAvif, StatueAvif320, StatueAvif480, StatueAvif768, StatueAvif1080,
            StatuePng, StatuePng320, StatuePng480, StatuePng768, StatuePng1080,
            StatueWebp, StatueWebp320, StatueWebp480, StatueWebp768, StatueWebp1080,
            StatueAvifHalf, StatueAvif320Half, StatueAvif480Half, StatueAvif768Half, StatueAvif1080Half,
            StatuePngHalf, StatuePng320Half, StatuePng480Half, StatuePng768Half, StatuePng1080Half,
            StatueWebpHalf, StatueWebp320Half, StatueWebp480Half, StatueWebp768Half, StatueWebp1080Half,
            clicks: 0,
            isPortrait: window.innerHeight > window.innerWidth,
        }
    },
    mounted() {
        window.addEventListener('resize', this.updateScreenSize);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.updateScreenSize);
    },
    methods: {        
        animationItems() {       
            const button = document.getElementById("about-me-statue");
            if (this.clicks % 2 === 0) {
                button.classList.remove("animate__slideOutDown");
                button.classList.add("animate__slideInUp");
            } else { 
                button.classList.add("animate__slideOutDown");
                button.classList.remove("animate__slideInUp");
            }
            this.clicks++;
        },
        updateScreenSize() {
            this.isPortrait = window.innerHeight > window.innerWidth;
        },
    },
    
}
</script>

<style scoped>
.animate__fadeIn{
    animation-duration: 3s; 
}
section#about-me {
    height: auto;
    overflow: hidden;
    padding: 0 5vw;
}
.container{
    height: 90vh;
    position: relative;
    top: -17.85vh;
    display: flex;
    justify-content: center;
    margin-bottom: -90vh;
}
.container-text{
    height: calc(90vh - 17.85vh);
    position: relative;
    margin-bottom: -90vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.nice{
    text-align: right;
    padding-bottom: 2.5vh;
}
.just{
    max-width: 50vw;
}
.swiper-container{
    position: relative;
    height: 90vh;
    margin-bottom: -90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.swiper-back{
    position: relative;
    z-index: -2;
    transform: scaleX(-1);
}
</style>

<style>
.about-me-statue{
    height: 90vh;
    max-height: 90vh;
    min-height: 90vh;
    width: auto;
}
section#about-me .accordion-collapse.collapse.show{
    height: calc(90vh - 17.85vh);
}
@media only screen and (orientation : portrait){
    .about-me-statue{
        position: absolute;
        right: -50vw;
    }
}
</style>